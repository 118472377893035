import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../hooks/useApp";
import { Categories } from "../../services/categories/CategoriesInterfaces";
import { getRequestCategories } from "../../services/categories/CategoriesService";
import { Complaint, ComplaintUser } from "../../services/complaint/ComplaintInterfaces";
import { createRequest, getRequestLastComplaintByUserId, updateRequest } from "../../services/complaint/ComplaintService";
import { properties } from "../../utils/Properties_es";

const ComplaintForm = () => {
    const { t } = useTranslation();

    const [textComplaint, setTextComplaint] = useState<string>("");
    const [textSubject, setTextSubject] = useState<string>("");
    const [idComplaint, setIdComplaint] = useState<number>(0);
    const [textEmail, setTextEmail] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoriesData, setCategoriesData] = useState<any>([]);

    const {
        isLoading,
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authInfo,
    } = useApp();

    const MAX_COMPLAINT_LENGTH = 220;
    const MAX_SUBJECT_LENGTH = 50;
    const MAX_EMAIL_LENGTH = 50;

    const onTextChange = (e: any) => setTextComplaint(e.target.value);
    const onTextChangeSubject = (e: any) => setTextSubject(e.target.value);
    const onTextChangeEmail = (e: any) => setTextEmail(e.target.value);
    const onTextChangeCategory = (e: any) => setSelectedCategory(e.target.value);

    const handleReset = () => {
        setTextComplaint("");
        setTextSubject("");
        setIdComplaint(0);
        setSelectedCategory({});
    }

    const createObject = (id: number, complaint: string, subject: string, email: string, status: number, categoryObject: Categories): Complaint => {
        const user: ComplaintUser = {
            userCreated: null,
            userModified: null,
            id: authInfo.id,
            identification: null,
            firstName: null,
            lastName: null,
            identificationType: null,
            email: null,
            phone: null,
            birthDate: null,
            sex: null,
            canton: null,
            province: null,
            district: null,
            status: null,
            role: null
        }

        const complaintObj: Complaint = {
            userCreated: authInfo.username,
            userModified: id !== null && id !== 0 ? authInfo.username : null,
            id: id,
            description: complaint,
            user: user,
            subject: subject,
            category: categoryObject,
            email: email,
            status: status
        }

        return complaintObj;
    };

    const saveComplaint = async (status: number) => {
        if ((textComplaint === undefined || textComplaint === null || textComplaint.length === 0) || textComplaint.length > MAX_COMPLAINT_LENGTH ||
            (textSubject === undefined || textSubject === null || textSubject.length === 0) || textSubject.length > MAX_SUBJECT_LENGTH ||
            (textEmail === undefined || textEmail === null || textEmail.length === 0) || textEmail.length > MAX_EMAIL_LENGTH ||
            (selectedCategory === undefined || selectedCategory === null || selectedCategory?.id === 0)) {
            setErrorMsg && setErrorMsg(properties.VALIDATE_REQUIRED_FIELDS);
            return;
        }


        setLoading && setLoading(true);
        try {
            const data: Complaint = createObject(idComplaint, textComplaint, textSubject, textEmail, status, selectedCategory);
            let createData: any = null;
            if (data.id && data.id !== 0) {
                createData = await updateRequest({
                    ...data
                });
            } else {
                createData = await createRequest({
                    ...data
                });
                if (createData && createData?.id && createData?.id > 0)
                    setIdComplaint(createData.id);
            }

            if (!createData) {
                setErrorMsg && setErrorMsg(properties.ERROR_CREATING_RECORD);
                setLoading && setLoading(false);
                return;
            }
            if (status === 1)
                handleReset();
            setLoading && setLoading(false);
            setSuccessMsg && setSuccessMsg(properties.SUCCESSFULLY_CREATED_RECORD);
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const status = event.currentTarget.getAttribute("data-status");
        await saveComplaint(Number(status));
    };

    const getCategories = async () => {
        setLoading && setLoading(true);
        setCategoriesData([]);
        try {
            let data = await getRequestCategories();
            if (data) {
                setCategoriesData(data);
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    const getLastComplaint = async () => {
        setLoading && setLoading(true);
        try {
            let data = await getRequestLastComplaintByUserId(authInfo.id);
            if (data && data?.id && data?.id !== 0) {
                const categoryObjId: number = data?.category?.id ? data?.category?.id : 0;
                const categoryObj = categoriesData.content.find((category: Categories) => {
                    if (category.id === categoryObjId)
                        return category;
                });
                setSelectedCategory(categoryObj);
                setTextEmail(data?.email ? data.email : '');
                setIdComplaint(data?.id ? data.id : 0);
                setTextSubject(data?.subject ? data.subject : '');
                setTextComplaint(data?.description ? data.description : '');
            }
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message);
        }
    };

    useEffect(() => {
        setTextEmail(authInfo?.username);
        getCategories();
    }, [authInfo]);

    useEffect(() => {
        if (categoriesData && categoriesData?.content?.length > 0)
            getLastComplaint();
    }, [categoriesData]);

    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={8}
            >
                <Grid item xs={10}>
                    <Stack
                        spacing={1}
                        direction="row"
                        sx={{ paddingTop: 10 }} >
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                error={textSubject.length > MAX_SUBJECT_LENGTH}
                                id="standard-static-subject"
                                label={t("subject")}
                                onChange={onTextChangeSubject}
                                value={textSubject}
                                inputProps={{ maxLength: MAX_SUBJECT_LENGTH }}
                                variant="outlined"
                                helperText={textSubject.length > MAX_SUBJECT_LENGTH ? `La cantidad máxima de caracteres permitida es de ${MAX_SUBJECT_LENGTH}` : ''}
                            />
                        </FormControl>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{ paddingTop: 1 }}
                        spacing={1}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                error={textEmail.length > MAX_EMAIL_LENGTH}
                                id="standard-static-email"
                                label={t("email")}
                                onChange={onTextChangeEmail}
                                value={textEmail}
                                variant="outlined"
                                helperText={textEmail.length > MAX_EMAIL_LENGTH ? `La cantidad máxima de caracteres permitida es de ${MAX_EMAIL_LENGTH}` : ''}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}  >
                            <InputLabel id="demo-simple-select-label">{t("category")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCategory}
                                label={t("category")}
                                onChange={onTextChangeCategory}
                                variant="outlined"
                            >
                                {categoriesData &&
                                    categoriesData.content &&
                                    categoriesData.content.map((row: any, i: number) => (
                                        <MenuItem
                                            key={row.id}
                                            value={row}
                                        >
                                            {row.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack
                        spacing={1}
                        direction="row"
                        sx={{ paddingTop: 1 }} >
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                error={textComplaint.length > MAX_COMPLAINT_LENGTH}
                                id="standard-multiline-static-complaint"
                                label={t("labelComplaint")}
                                onChange={onTextChange}
                                value={textComplaint}
                                multiline
                                rows={5}
                                variant="outlined"
                                inputProps={{ maxLength: MAX_COMPLAINT_LENGTH }}
                                helperText={textComplaint.length > MAX_COMPLAINT_LENGTH ? `La cantidad máxima de caracteres permitida es de ${MAX_COMPLAINT_LENGTH}` : ''}
                            />
                            <Typography
                                sx={{ mb: 2 }}
                                variant="body2"
                                color={textComplaint.length > MAX_COMPLAINT_LENGTH ? 'error' : 'textSecondary'}
                                align="right"
                            >
                                {textComplaint.length}/{MAX_COMPLAINT_LENGTH} caracteres
                            </Typography>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={10}
                >
                    <Stack spacing={2} direction="row">
                        <Button
                            disabled={!textComplaint || !selectedCategory || !selectedCategory?.id || !textEmail || !textSubject || isLoading}
                            variant="contained"
                            onClick={handleSubmit}
                            data-status="0"
                        >
                            {t("save")}
                        </Button>
                        <Button
                            disabled={!textComplaint || !selectedCategory || !selectedCategory?.id || !textEmail || !textSubject || isLoading}
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            data-status="1"
                        >
                            {t("send")}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default ComplaintForm;
