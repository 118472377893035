
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Visibility from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FancyTablePagination from "../../components/util/FancyTablePagination";
import { useApp } from "../../hooks/useApp";
import { getRequestCategories } from "../../services/categories/CategoriesService";
import { changeStatusRequest, getRequest } from "../../services/complaint/ComplaintService";
import theme from "../../theme/theme";

const ComplaintList = () => {
  const { t } = useTranslation();

  const {
    isAdmin,
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authInfo
  } = useApp();

  const [complaintData, setComplaintData] = useState<any>([]);
  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [complaintObject, setComplaintObject] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<string>('');
  const [allCategory, setAllCategory] = useState<any>({ id: 0, name: 'Todos' });

  /**
   * Metodo encargado de cerrar el modal
   */
  const handleClose = () => {
    setOpen(false);
    setComplaintObject(null);
  };

  /**
   * Metodo encargado de la apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const id = event.currentTarget.getAttribute("data-id");
    if (id) {
      const complaintObj = complaintData.content.find((p: any) => p.id === parseInt(id));
      if (complaintObj) {
        setComplaintObject(complaintObj);
      }
    }
    setOpen(true);
  };

  /**
   * Metodo encargado de la apertura de modal
   */
  const handleChangeStatusFinish = async (event: any) => {
    event.preventDefault();
    const id = event.currentTarget.getAttribute("data-id");
    if (id) {
      changeStatusComplaint(id, 2);
    }
  };


  /**
   * Metodo encargado de cambiar el estado de la denuncia
   * @param filter
   * @param currentPage
   */
  const changeStatusComplaint = async (id: number, status: number) => {
    setLoading && setLoading(true);
    try {
      let data = await changeStatusRequest(id, status);
      handleFetchData(0);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter?: string
  ) => {
    setLoading && setLoading(true);
    setComplaintData([]);
    try {
      const sort: string = "creationDate,desc";
      const filterText: string = filter ? filter : '';
      const userId: number = isAdmin ? 0 : authInfo.id;
      const categoryId: number = category?.id ? category?.id : 0;
      let data = await getRequest(authInfo.tokenInfo.access_token, currentPage, sort, filterText, userId, categoryId);
      if (data) {
        setComplaintData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de cargar categorias
   * @param filter
   * @param currentPage
   */
  const getCategories = async () => {
    setLoading && setLoading(true);
    setCategoriesData([]);
    try {
      let data = await getRequestCategories();
      if (data) {
        setCategoriesData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de la páginación de la tabla
   * @param event
   * @param newPage
   */
  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage,);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  /**
   * Metodo encargado de la carga inicial
   */
  useEffect(() => {
    const dataInit = async () => {
      await getCategories();
    };
    dataInit();

  }, [isAdmin]);

  useEffect(() => {

  }, [authInfo]);

  /**
   * Efecto al cambiar filtro de estado
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, preFilter);
    };
    dataInit();
  }, [category]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={8}
      >
        <Grid item md={12} sm={6} xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ paddingTop: 5 }}
          >
            <Box
              display="flex"
              alignContent="center"
              alignItems="center"
              gap={4}
            >
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Stack
                sx={{
                  paddingTop: 2
                }}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <FormControl sx={{
                  width: "100%"
                }}>
                  <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label={t('category')}
                    onChange={handleChange}
                  >
                    <MenuItem
                      key={0}
                      value={allCategory}
                    >
                      {t('all')}
                    </MenuItem>
                    {categoriesData &&
                      categoriesData.content &&
                      categoriesData.content.map((row: any, i: number) => (
                        <MenuItem
                          key={row.id}
                          value={row}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                sx={{
                  paddingTop: 2,
                  paddingRight: 2
                }}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <TextField
                  placeholder={t('filter')}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "0.2rem 0 0 0.2rem",
                    },
                    "& fieldset": {
                      borderRightWidth: "0",
                    },
                  }}
                  value={preFilter}
                  onChange={(e: any) => {
                    setPreFilter(e.target.value);
                    if (e.target.value === "") {
                      handleFetchData(0, "");
                    }
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleApplyFilter}
                  sx={{
                    borderRadius: "0 0.2rem 0.2rem 0!important",
                    padding: "1.5px 16px!important",
                    minWidth: '20px !important',
                  }}
                >
                  {t("search")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack
            direction="column"
            sx={{}} >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('name')}</TableCell>
                    <TableCell align="center">{t('category')}</TableCell>
                    <TableCell align="center">{t('subject')}</TableCell>
                    <TableCell align="center">{t('date')}</TableCell>
                    <TableCell align="center">{t('status')}</TableCell>
                    <TableCell align="center">{t('action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {complaintData &&
                    complaintData.content &&
                    complaintData.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">{row.user ? (row.user.firstName ? row.user.firstName : '') + ' ' + (row.user.lastName ? row.user.lastName : '') : ''}</TableCell>
                        <TableCell align="center">{row.category?.name ? row.category.name : ''}</TableCell>
                        <TableCell align="center">{row.subject ? row.subject : ''}</TableCell>
                        <TableCell align="center">{row.creationDate ? row.creationDate.substring(0, 10) : ''}</TableCell>
                        <TableCell align="center">{
                          row.status == 0 && !isAdmin ?
                            (
                              <Tooltip title="Denuncia sin enviar" placement="top-start">
                                <ReportProblemIcon color="secondary" />
                              </Tooltip>
                            )
                            : (
                              row.status == 1 && !isAdmin ?
                                (
                                  <Tooltip title="Denuncia enviada" placement="top-start">
                                    <ForwardToInboxIcon color="primary" />
                                  </Tooltip>
                                )
                                : (
                                  row.status == 1 && isAdmin ?
                                    (
                                      <Tooltip title="Denuncia pendiente de atender" placement="top-start">
                                        <ReportProblemIcon color="secondary" />
                                      </Tooltip>
                                    )
                                    : (
                                      row.status == 2 ?
                                        (
                                          <Tooltip title="Denuncia procesada" placement="top-start">
                                            <AssignmentTurnedInIcon color="primary" />
                                          </Tooltip>
                                        ) :
                                        (
                                          ''
                                        )
                                    )
                                )
                            )
                        }</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Ver denuncia" placement="top-start">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              onClick={handleOpenModal}
                              data-id={row.id}
                            >
                              <Visibility />
                            </IconButton>

                          </Tooltip>
                          {(row.status && row.status == 1 && isAdmin) ?
                            (
                              <Tooltip title="Pasar denuncia a procesada" placement="top-start">
                                <IconButton
                                  aria-label="edit"
                                  component="label"
                                  color="secondary"
                                  sx={{
                                    "&:hover": {
                                      color: theme.palette.primary.dark,
                                    },
                                  }}
                                  onClick={handleChangeStatusFinish}
                                  data-id={row.id}
                                >
                                  <ChangeCircleIcon />
                                </IconButton>

                              </Tooltip>
                            ) :
                            (
                              ''
                            )
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={complaintData?.content?.length > 0 ? complaintData?.content?.length : 0}
              rowsPerPage={complaintData.size}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalElements={complaintData.totalElements}
              totalPages={complaintData.totalPages}
            />
          </Stack>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('complaint')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{t('name')}:</b>
            <br />
            {complaintObject?.user ? (complaintObject?.user?.firstName ? complaintObject.user.firstName : '') + ' ' + (complaintObject?.user?.lastName ? complaintObject.user.lastName : '') : ''}
            <br />
            <br />
            <b>{t('email')}:</b>
            <br />
            {complaintObject?.email ? complaintObject.email : ''}
            <br />
            <br />
            <b>{t('subject')}:</b>
            <br />
            {complaintObject?.subject ? complaintObject.subject : ''}
            <br />
            <br />
            <b>{t('category')}:</b>
            <br />
            {complaintObject?.category?.name ? complaintObject.category.name : ''}
            <br />
            <br />
            <b>{t('date')}:</b>
            <br />
            {complaintObject?.creationDate ? complaintObject.creationDate.substring(0, 10) : ''}
            <br />
            <br />
            <b>{t('complaint')}:</b>
            <br />
            {complaintObject?.description ? complaintObject.description : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ComplaintList;