//Urls Base
export const UrlBase = {
  base_url: window.REACT_APP_BASE_URL,
  base: window.REACT_APP_CORE,
  //app_login: window.REACT_APP_LOGIN_BASE_URL,
  api_context: window.REACT_APP_API_CONTEXT,
  security_appclient_id: window.REACT_APP_SECURITY_APPCLIENT_ID,
  security_appclient_pass: window.REACT_APP_SECURITY_APPCLIENT_PASS,
  security_appuser_id: window.REACT_APP_SECURITY_APPUSER_ID,
  security_appuser_pass: window.REACT_APP_SECURITY_APPUSER_PASS,
  registration_x_page: window.REACT_APP_REGISTRATION_X_PAGE,
  modal_registration_x_page: window.REACT_APP_MODAL_REGISTRATION_X_PAGE,
  sesion_timeout: window.REACT_APP_SESION_TIMEOUT,
  sesion_timeout_wait: window.REACT_APP_SESION_TIMEOUT_WAIT,

};
