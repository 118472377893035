import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import {Controller} from "react-hook-form";
import {DataItemCanton, DataItemProvince} from "../../types/auth/Register";



const CantonSelect = ({control, name, value, onChange, error, helperText, variant, register, data, province}: any) => {
  const {t} = useTranslation();

  const cantones = province!=0 && data.filter((item: DataItemProvince) => item.idProvince == province)[0].cantons;

  return (
      <Grid item xs={12}>
        <FormControl sx={{width: "100%"}} error={error}>
          <InputLabel>{t("canton")}</InputLabel>
          <Controller
              name={name}
              control={control}
              render={({field}) => (
                  <Select
                      {...register("canton")}
                      name={name}
                      variant="standard"
                      label={t("canton")}
                      value={value != 0 ? value : null}
                      onChange={onChange}
                      autoWidth
                      sx={{
                        backgroundColor: variant ? "transparent" : "#ffffff",
                        borderBottom: variant && "1px solid #8bc8bc",
                      }}
                  >
                    {cantones && cantones.map((canton:DataItemCanton) => (
                        <MenuItem key={canton.idCanton} value={canton.idCanton}>
                          {canton.nameCanton}
                        </MenuItem>
                    ))}
                  </Select>
              )}
          />
          <FormHelperText sx={{color: error ? "red" : "initial"}}>
            {helperText}
          </FormHelperText>
        </FormControl>
      </Grid>
  );
};

export default CantonSelect;
