import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const GenderSelect = ({ value, onChange, error, helperText, variant }: any) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} error={error}>
                <InputLabel>{t("gender")}</InputLabel>
                <Select
                    variant="standard"
                    label={t("gender")}
                    value={value ?? ""}
                    onChange={onChange}
                    autoWidth
                    sx={{
                        backgroundColor: variant ? "transparent" : "#ffffff",
                        borderBottom: variant && "1px solid #8bc8bc",
                    }}
                >
                    <MenuItem value="female">{t("female")}</MenuItem>
                    <MenuItem value="male">{t("male")}</MenuItem>
                    <MenuItem value="other">{t("other")}</MenuItem>
                </Select>
                <FormHelperText sx={{ color: error ? "red" : "initial" }}>
                    {helperText}
                </FormHelperText>
            </FormControl>
        </Grid>
    );
};

export default GenderSelect;
