
import {
  Grid,
  Link,
  Stack,
  SvgIcon,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Facebook, MailIcon, PhoneIcon } from "../components/svg/icons/SvgIcons";



const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.dark,
  display: "inline-block",
  marginBottom: "0.15rem",
}));

const LargerFacebookIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "2rem",
  width: 26,
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const HoverableIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "1.625rem",
  width: 26,
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const Footer = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="row"
        sx={{ mt: "1.5rem", mb: "0.5rem" }}
      >
        <Link
          href="https://www.facebook.com/Municipalidaddeaseri/"
          target="_blank"
          underline="none"
          color={theme.palette.secondary.dark}
        >
          <LargerFacebookIcon>
            <Facebook />
          </LargerFacebookIcon>
        </Link>
      </Stack>
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <HoverableIcon>
              <MailIcon />
            </HoverableIcon>
            <Typography>
              <LinkText href="servicioalusuario@aserri.go.cr">
                servicioalusuario@aserri.go.cr
              </LinkText>
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <HoverableIcon>
              <PhoneIcon />
            </HoverableIcon>
            <Typography>2230-3078 / 2103-0000</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack justifyContent="center" alignItems="center">
        <Typography color="secondary.dark" variant="body1">
          Copyright © 2024 Municipalidad de Aserrí | Diseño y Desarrollo <span style={{color  :"#CECECE"}}> bitcode-enterprise.com</span>
        </Typography>
      </Stack>
    </>
  );
};

export default Footer;

