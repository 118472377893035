// AuthContext.jsx
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useApp } from "../hooks/useApp";
import jsonDataProvinces from "../resources/data/provinces_data.json";
import {
    SVChangePassRequest,
    SVCreateUserRequest,
    SVRecoveryPassRequest,
    SVSessionValidateRequest,
    SVloginCodeRequest,
    SVloginRequest,
    SVCancelSubscriptionRequest
} from "../services/auth/AuthService";
import { AuthContextType, UserType } from "../types/AuthContext";
import { setAuthData } from "../utils/LocalStorageManager";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => useContext(AuthContext) as AuthContextType;

const AuthProvider = ({ children }: any) => {

    const [user, setUser] = useState<UserType | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showCode, setShowCode] = useState(false);
    // Is valid recovery password
    const [isValid, setIsValid] = useState<boolean>(false)
    const [documentType, setDocumentType] = useState<string>("E");
    // Is valid register
    const [documentTypeRegister, setDocumentTypeRegister] = useState<string>("C");
    const [gender, setGender] = useState("male");

    const dataProvinces = jsonDataProvinces;

    const handleDocumentTypeChange = (value: string) => {
        setDocumentType(value);
    };

    const handleDocumentTypeRegisterChange = (value: string) => {
        setDocumentTypeRegister(value);
    };

    const handleGenderChange = (value: string) => {
        setGender(value);
    };

    const { code } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        setAuthInfo,
        setAuthenticate,
        setIsAdmin,
    } = useApp();

    const validateUsername = (value: string, documentType: string): string | true => {
        if (documentType === "C") {
            if (!/[0-9]{9}/.test(value)) {
                return "La cédula debe contener 9 números";
            }
        } else if (documentType === "D") {
            if (!/[0-9]{12}/.test(value)) {
                return "El DIMEX debe contener 12 números";
            }
        } else {
            if (!/\S+@\S+\.\S+/.test(value)) {
                return "Correo electrónico inválido";
            }
        }
        return true;
    };

    const loginValidation = Yup.object().shape({
        document: Yup.string()
            .required(() => {
                if (documentType === 'C') {
                    return `${t("Cédula")} ${t("isRequiredF")}`;
                } else if (documentType === 'D') {
                    return `${t("DIMEX")} ${t("isRequired")}`;
                } else if (documentType === 'E') {
                    return `${t("Correo electrónico")} ${t("isRequired")}`;
                } else {
                    return t("isRequired");
                }
            })
            .test("username-validation", "", function (value) {
                const errorMessage = validateUsername(value, documentType);
                return errorMessage === true || this.createError({ message: errorMessage });
            }),
        password: Yup.string().required("Contraseña es requerida"),
        code: Yup.string(),
    });

    const step1Validation = Yup.object().shape({
        name: Yup.string().required(`${t("name")} ${t("isRequired")}`),
        lastName: Yup.string().required(`${t("lastName")} ${t("isRequired")}`),
        document: Yup.string()
            .required(`${documentTypeRegister === 'C' ? t("cedula") : t("dimex")}  ${documentTypeRegister === 'C' ? t("isRequiredF") : t("isRequired")}`)
            .test("username-validation", "", function (value) {
                const errorMessage = validateUsername(value, documentTypeRegister);
                return errorMessage === true || this.createError({ message: errorMessage });
            }),
        phone: Yup.string().required(`${t("phone")} ${t("isRequired")}`),
        username: Yup.string().required(`${t("email")} ${t("isRequired")}`),
        dob: Yup.string().required(`${t("birthDate")} ${t("isRequired")}`),
    });

    const step2Validation = Yup.object().shape({
        province: Yup.number()
            .required(`${t("provincia")} ${t("isRequired")}`)
            .typeError(`${t("provincia")} ${t("isRequired")}`),
        canton: Yup.number().required(`${t("canton")} ${t("isRequired")}`)
            .typeError(`${t("canton")} ${t("isRequired")}`),
        district: Yup.number().required(`${t("distrito")} ${t("isRequired")}`)
            .typeError(`${t("distrito")} ${t("isRequired")}`)
    });


    const forgotPasswordValidation = Yup.object().shape({
        password: Yup.string().required("Contraseña es requerido"),
        repeatPassword: Yup.string().required("Confirmar contraseña es requerido"),
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            if (!showCode) {
                let request: any = await SVloginCodeRequest({
                    ...data,
                    documentType: documentType,
                });
                setLoading && setLoading(false);
                setShowCode(true);
                setSuccessMsg && setSuccessMsg(request?.message);
                return;
            }
            //Authenticate user
            const loginData = await SVloginRequest({
                ...data,
                documentType: documentType,
            });
            await loginProvider(loginData);
            setLoading && setLoading(false);

        } catch (error: any) {
            setLoading && setLoading(false);

            if (error.message === "006") {
                navigate("/rememberpassword", { replace: true });
                setErrorMsg && setErrorMsg(t("passwordExpiredMessage"));
            } else {
                setErrorMsg &&
                    setErrorMsg(
                        error.message === "001"
                            ? t("errorUserOrPass")
                            : error.message === "401" || error.message === "500"
                                ? t("codeInvalid")
                                : t("loginGeneralError")
                    );
            }
        }
    };

    const onSubmitForgotPassword = async (data: any) => {
        try {
            setLoading && setLoading(true);

            const resultData: any = await SVChangePassRequest({
                id: code,
                password: data.password,
            });

            setSuccessMsg && setSuccessMsg(resultData.info);
            setLoading && setLoading(false);
            navigate("/", { replace: true });
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg &&
                setErrorMsg(
                    error.message
                        ? error.message
                        : t("loginGeneralError")
                );
        }
    };

    const onSubmitRecoveryPassword = async (data: any) => {
        try {
            setLoading && setLoading(true);
            //Authenticate user
            await SVRecoveryPassRequest(data);
            setSuccessMsg && setSuccessMsg(t("recoveryEmailSend"));
            setLoading && setLoading(false);
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg &&
                setErrorMsg(
                    error.message
                        ? t("errorSendingEmail")
                        : t("loginGeneralError")
                );
        }
    };

    const onSubmitCancelSubscription = async (data: any) => {
        try {
            setLoading && setLoading(true);
            //Call service user
            const resultData: any = await SVCancelSubscriptionRequest(data);
            setSuccessMsg && setSuccessMsg(resultData.info);
            setLoading && setLoading(false);
            
        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg &&
                setErrorMsg(
                    error.message
                        ? error.message
                        : t("cancelSubscriptionError")
                );
        }
    };

    const onSubmitRegister = async (data: any) => {

        const newData = { ...data, documentType: documentTypeRegister, gender: gender };
        let provinceObject = dataProvinces.filter((item) => item.idProvince == newData.province)[0]
        let cantonObject = provinceObject.cantons.filter((item) => item.idCanton == newData.canton)[0]
        let districObject = cantonObject.districts.filter((item) => item.idDistrict == newData.district)[0]
        let identificationType = newData.documentType == "C" ? 1 : 2;
        let genderSelected = newData.gender == "male" ? "M" : newData.gender == "female" ? "F" : "O";

        const completeData = {
            ...newData,
            province: provinceObject.nameProvince,
            canton: cantonObject.nameCanton,
            district: districObject.nameDistrict,
            gender: genderSelected,
            identificationType: identificationType
        };
        try {
            setLoading && setLoading(true);
            //Authenticate user
            await SVCreateUserRequest(completeData);
            setSuccessMsg && setSuccessMsg(t("registerSuccess",{email:completeData.username}));
            setLoading && setLoading(false);

        } catch (error: any) {
            setLoading && setLoading(false);
            setErrorMsg && setErrorMsg(error.message)
        }
    };

    const loginProvider = async (loginData: any) => {

        try {
            const sessionCode = loginData.authCode;
            const email = loginData.username;
            if (sessionCode && email) {
                const loginDataValidate = await SVSessionValidateRequest(
                    email,
                    sessionCode
                );

                //save login data in storage
                const authData = await setAuthData(
                    "authAserri",
                    loginDataValidate
                );

                if (!authData) {

                    setAuthenticate && setAuthenticate(false);
                    return;
                }

                const decodeToken: any = await jwtDecode(
                    authData?.tokenInfo?.access_token
                );
                const expiresAt = await decodeToken?.exp;
                let valid = new Date().getTime() / 1000 < expiresAt;
                if (valid) {
                    // console.log('is valid: ' + valid);

                    setAuthenticate && setAuthenticate(valid);
                    setAuthInfo && setAuthInfo(authData);
                    // console.log(authData);
                    setIsAdmin && setIsAdmin(authData.userRole.id === 1 || authData.userRole.id === 3);
                    authData.userRole.id === 1
                        ? navigate("/dashboard/home", { replace: true })
                        : navigate("/dashboard/home", { replace: true });
                    return;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const logoutProvider = async (e: any) => {
        //e.preventDefault();
        await setAuthData("authAserri", null);
        setAuthenticate && setAuthenticate(false);
        setShowCode(false);
        navigate("/");
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                showPassword,
                setShowPassword,
                showCode,
                setShowCode,
                loginProvider,
                loginValidation,
                forgotPasswordValidation,
                handleClickShowPassword,
                onSubmit,
                onSubmitForgotPassword,
                onSubmitRecoveryPassword,
                onSubmitCancelSubscription,
                isValid,
                setIsValid,
                logoutProvider,
                documentType,
                setDocumentType,
                handleDocumentTypeChange,
                documentTypeRegister,
                setDocumentTypeRegister,
                handleDocumentTypeRegisterChange,
                onSubmitRegister,
                gender,
                setGender,
                handleGenderChange,
                step1Validation,
                step2Validation,
                dataProvinces
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
export { AuthProvider };

export default AuthContext;