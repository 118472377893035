
import { CONSTANT } from "../../utils/Constants";
import { publicFetch } from "../../utils/CustomFetch";
import { URL } from "../../utils/UrlConstants";

export const SVloginCodeRequest = async (data:any) => {
    let responseData = {};
    const requestBody = {
        userName: data.document,
        password: data.password,
        userPortal: "Administrativo",
        loginType: data.documentType != "E" ? "I" : data.documentType
    };

    await publicFetch
        .post(URL.LOGIN_TWOFACTORCODE_REQUEST, JSON.stringify(requestBody), {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: { response: { data: { code: string | undefined; message: string | undefined; }; }; }) => {
            if (error.response.data.code === "001" || error.response.data.code === "006" ) {
                throw new Error(error.response.data.code);
            } else {
                throw new Error(error.response.data.message);
            }
        });

    return responseData;
};

export const SVloginRequest = async (data:any) => {
    let responseData = {};
    const requestBody = {
        userName: data.document,
        password: data.password,
        code: data.code,
        userPortal: "Administrativo",
        loginType: data.documentType != "E" ? "I" : data.documentType
    };
    await publicFetch
        .post(URL.LOGIN_REQUEST, JSON.stringify(requestBody), {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: any) => {
            if (
                error.response.data.status === 401 ||
                error.response.data.status === 500
            ) {
                throw new Error(error.response.data.status);
            } else {
                throw new Error(error.response.data.message);
            }
        });

    return responseData;
};

export const SVRecoveryPassRequest = async (data: any) => {
    let responseData = {};
    const requestBody = {
        email: data.username,
    };
    //console.log("SVRecoveryPassRequest requestBody=", requestBody);
    await publicFetch
        .post(URL.LOGIN_RECOVERY_PASS_REQUEST, JSON.stringify(requestBody), {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: any) => {
            responseData = await response.data;
        })
        .catch((error: any) => {
            throw new Error("Error envíando mail");
        });

    return responseData;
};

export const SVChangePassRequest = async (data:any) => {
    let responseData = {};
    const requestBody = {
        id: data.id,
        password: data.password,
    };
    await publicFetch
        .put(URL.LOGIN_CHANGE_PASS_REQUEST, JSON.stringify(requestBody), {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: any) => {
            throw new Error(error);
        });

    return responseData;
};

export const SVActiveUserRequest = async (email: any, code: any) => {
    let responseData = {};
    const params = new URLSearchParams([
        ["username", email],
        ["code", code],
    ]);
    await publicFetch
        .get(URL.LOGIN_ACTIVE_USER_REQUEST, { params })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: any) => {
            throw new Error(error);
        });

    return responseData;
};

export const SVSessionValidateRequest = async (email: any, sessionCode: any) => {
    let responseData = {};
    const requestBody = {
        userName: email,
        sessionCode: sessionCode,
    };
    await publicFetch
        .post(URL.SESSIONVALIDATE_REQUEST, JSON.stringify(requestBody), {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: { data: {} | PromiseLike<{}>; }) => {
            responseData = await response.data;
        })
        .catch((error: { response: { status: number; }; }) => {
            if (
                error.response &&
                (error.response.status === 401 || error.response.status === 500)
            ) {
                throw new Error(
                    "El código digitado es inválido o ha expirado, por favor solicite un nuevo código"
                );
            }
            throw new Error(
                "Ha ocurrido un error inesperado, por favor intente más tarde"
            );
        });

    return responseData;
};


export const SVCreateUserRequest = async (data: any) => {
    let responseData = {};
    const requestBody = {
        firstName: data.name,
        lastName: data.lastName,
        identification: data.document,
        identificationType: {id: data.identificationType},
        email: data.username,
        phone: data.phone,
        birthDate: data.dob,
        sex: data.gender,
        canton: data.canton,
        province: data.province,
        district: data.district,
        password: data.password,
        roleID: 2,
        userCreated: data.username,
    };
    await publicFetch
    .post(URL.CREATEUSER_REQUEST, JSON.stringify(requestBody),{
        headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response: { data: {} | PromiseLike<{}>; }) => {
        responseData = await response.data;
    })
    .catch((error: { response: { data: {message: string}, status: number }; }) => {
        if (
            error.response &&
            (error.response.status === 401 || error.response.status === 500)
        ) {
            throw new Error(
                error.response.data.message
            );
        }
        throw new Error(
            "Ha ocurrido un error inesperado, por favor intente más tarde"
        );
    });

    return responseData;
};

export const SVCancelSubscriptionRequest = async (data: any) => {
    let responseData = {};
    const requestBody = {
        email: data.username,
    };
    await publicFetch
        .post(URL.CANCEL_SUBSCRIPTION_REQUEST, JSON.stringify(requestBody), {
            headers: { ...CONSTANT.HEADER_TYPE },
        })
        .then(async (response: any) => {
            responseData = await response.data;
        })
        .catch((error: any) => {
            let responseError = "Error cancelando la suscripción"; 
            if (error.response && error.response.status === 404 && error.response.data && error.response.data.info) {
                responseError = error.response.data.info;
            }
            throw new Error(responseError);
        });

    return responseData;
};