import { SvgIcon } from '@mui/material';
import { ReactComponent as AccountStatementsIcon } from '../../../assets/icons/account_statements.svg';
import { ReactComponent as InternalDirectoryIcon } from '../../../assets/icons/internal_directory.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import { ReactComponent as PendingQueriesIcon } from '../../../assets/icons/pending_queries.svg';

export const AccountStatementsSvgIcon = (props: any) => {
    return <SvgIcon component={AccountStatementsIcon} viewBox='0 0 57 57' sx={{ fontSize: '10vh', minHeight: '6rem', minWidth: '6rem' }} {...props} />;
};

export const InternalDirectorySvgIcon = (props: any) => {
    return <SvgIcon component={InternalDirectoryIcon} viewBox='0 0 44 63' sx={{ fontSize: '10vh', minHeight: '6rem', minWidth: '6rem' }}  {...props} />;
};

export const LocationSvgIcon = (props: any) => {
    return <SvgIcon component={LocationIcon} viewBox='0 0 57 57' sx={{ fontSize: '10vh', minHeight: '6rem', minWidth: '6rem' }} {...props} />;
};

export const PendingQueriesSvgIcon = (props: any) => {
    return <SvgIcon component={PendingQueriesIcon} viewBox='0 0 66 66' sx={{ fontSize: '10vh', minHeight: '6rem', minWidth: '6rem' }} {...props} />;
};
