import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const DocumentTypeSelect = ({ value, onChange, isRegister, disabled, variant }: any) => {
    const { t } = useTranslation();

    const documentTypeOptions = [
        { value: "C", label: "Cédula" },
        { value: "D", label: "DIMEX" },
    ];

    if (!isRegister) {
        documentTypeOptions.push({ value: "E", label: "Correo electrónico" });
    }

    return (
        <FormControl sx={{ width: "100%" }}>
            <InputLabel>{t("selectDocument")}</InputLabel>
            <Select
                label={t("selectDocument")}
                value={value}
                onChange={onChange}
                disabled={disabled ?? false}
                autoWidth
                variant={variant}
                sx={{
                    backgroundColor: variant ? "transparent" : "#ffffff",
                    borderBottom: variant && "1px solid #8bc8bc",
                }}
            >
                {documentTypeOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DocumentTypeSelect;
