import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { step1, step2, step3 } from "../../../types/auth/Register";
import CustomPasswordChecklist from "../../../utils/PasswordField";

interface Step3Props {
    onBackStep: () => void;
    preFilledDataStep1?: step1 | null;
    preFilledDataStep2: step2 | null;
}

const Step3: FC<Step3Props> = ({ onBackStep, preFilledDataStep1, preFilledDataStep2 }) => {

    const { t } = useTranslation();
    const {
        showPassword,
        handleClickShowPassword,
        setIsValid,
        isValid,
        onSubmitRegister,
    } = useAuth();

    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        watch,
    } = useForm<step3>({
    });

    const watch_password = watch("password");
    const watch_repeatPassword = watch("repeatPassword");

    // Función para enviar los datos del formulario al componente padre
    const handleFormSubmit = () => {
        const data = {
            ...preFilledDataStep1,
            ...preFilledDataStep2,
            password: watch_password,
            repeatPassword: watch_repeatPassword,
        };
        onSubmitRegister(data);
    };

    return (

        <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xs={12}>
                <Controller
                    name={"password"}
                    control={control}
                    render={() => (
                        <TextField
                            fullWidth
                            size="small"
                            label={t("password")}
                            type={showPassword ? "text" : "password"}
                            variant="standard"
                            autoComplete="password"
                            {...register("password")}
                            helperText={errors.password && errors.password.message}
                            sx={{
                                "& .MuiInput-root:before": {
                                    borderBottomColor: "#8bc8bc!important",
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {!showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name={"repeatPassword"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            fullWidth
                            size="small"
                            label={t("confirmPassword")}
                            type={showPassword ? "text" : "password"}
                            variant="standard"
                            autoComplete="repeatPassword"
                            {...register("repeatPassword")}
                            helperText={errors.repeatPassword && errors.repeatPassword.message}
                            sx={{
                                "& .MuiInput-root:before": {
                                    borderBottomColor: "#8bc8bc!important",
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton onClick={handleClickShowPassword}>
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />
                {watch_password && (
                    <Grid item xs={12}>
                        <CustomPasswordChecklist
                            value={watch_password}
                            valueAgain={watch_repeatPassword}
                            onChange={(isValid: boolean) => {
                                setIsValid(isValid);
                            }}
                        />
                    </Grid>
                )}
                <Grid container justifyContent="content" mt={2} spacing={2}>
                    <Grid item xs={6}>
                        <Button onClick={onBackStep} variant="outlined" fullWidth sx={{ textTransform: "none" }}>
                            {t("back")}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={isValid ? handleSubmit(handleFormSubmit) : undefined}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            {t("register")}
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    );
};

export default Step3;
