import { useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Typography, useTheme, Stack, Paper, styled } from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { CONSTANT } from "../../utils/Constants";
import { SVActiveUserRequest } from "../../services/auth/AuthService";
import MessageManager from "../../utils/MessageManager";
import Loading from "../../utils/Loading";
import { SpaceLogo } from "../../components/svgicons/SvgIcons";
import TitleForm from "../../utils/TitleForm";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const Span = styled("span")(({ theme }) => ({
  color: "#326267",
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const ActiveRegister = () => {
  const theme = useTheme();
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    errorMsg,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();
  const { code, email } = useParams();
  const [active, setActive] = useState(false);

  useLayoutEffect(() => {
    const process = async () => {
      await handleActiveUser();
    };
    process();
    // eslint-disable-next-line
  }, []);

  const handleActiveUser = async () => {
    setLoading && setLoading(true);
    try {
      let responseData: any = await SVActiveUserRequest(email, code);
      if (!responseData && responseData?.info !== CONSTANT.STATUS_INFO_ACTIVE) {
        setLoading && setLoading(false);
        setErrorMsg && setErrorMsg(responseData.message);
        return;
      }
      setLoading && setLoading(false);
      setActive(true);
      setSuccessMsg && setSuccessMsg(responseData.message);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0, py: 4 }}
      >
        <img
          src="/escudo-aserri.jpg"
          alt="companylogo"
          width="150"
          style={{
            marginTop: "-1.5rem",
          }}
        />
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontSize: "1.50rem",
            color: theme.palette.info.dark,
            mt: `${theme.spacing(2)}!important`,
            mb: `${theme.spacing(2)}!important`,
          }}
        >
          Activar usuario 
        </Typography>
        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            background: "#F8F5F3",
            marginBottom: `${theme.spacing(3)}!important`,
            padding: {
              xs: theme.spacing(8),
              md: `${theme.spacing(8)} ${theme.spacing(12)}`,
            },
          }}
        >
          <TitleForm>Activar usuario</TitleForm>
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 3, fontSize: "1rem" }}
          >
            {active ? (
              <>¡Ha autenticado y activado su cuenta correctamente!</>
            ) : (
              <>Error en proceso de activar usuario</>
            )}
          </Typography>

          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo to="/">Regresar a inicio de sesión</LinkTo>
          </Typography>
        </Paper>
      </Content>
    </>
  );
};

export default ActiveRegister;
