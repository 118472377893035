import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#1B5D23',
        light: '#3CD400',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#851B23',
        dark: '#33312B'
      },
      success: {
        main: '#d32f2f'
      },
      text: {
        primary: '#2E2E2E',
        secondary: '#757575',
      },
      common: {
        black: '#000000',
        white: '#ffffff'
      }
    },
    typography: {
      fontFamily: ['Helvetica', 'Arial'].join(','),
      button: {
        fontSize: '1rem',
        fontWeight: 700,
        fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(',')
      },
      h1: {
        fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(',')
      },
      h2: {
        fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(',')
      },
      h3: {
        fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
        fontSize: "2rem"
      },
      h4: {
        fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(','),
        fontSize: "1.8rem"
      },
      h5: {
        fontSize: '1.25rem',
        fontFamily: ['Raleway', 'Helvetica', 'Arial'].join(',')
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: 16,
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #CACACA'
            },
            "& .MuiInput-root:before": {
              borderBottomColor: "#8bc8bc!important",
            },

            '& label': {
              color: '#666666'
            },
            '& .MuiInput-root.Mui-error:before': {
              borderBottomColor: 'red!important',
            },
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '8px!important'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: 'translate(14px, 9px) scale(1)'
          },
          shrink: {
            transform: 'translate(14px, -9px) scale(0.75)'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundColor: '#1B5D23',
            transitionProperty: 'all',
            borderRadius: 8,
            minWidth: 170,
            '&:hover': {
              // opacity: 0.8,
              backgroundColor: '#3B7B3F'
            },
            textTransform: 'unset',
            '&.Mui-disabled': {
              color: 'inherit!important',
              opacity: '0.5'
            }
          },
          containedSecondary: {
            backgroundColor: '#851B23',
            transitionProperty: 'all',
            borderRadius: 8,
            minWidth: 170,
            '&:hover': {
              opacity: 0.8,
              backgroundColor: '#851B23'
            },
            textTransform: 'unset',
            '&.Mui-disabled': {
              color: 'inherit!important',
              opacity: '0.5'
            }
          },
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            minWidth: 150,
            // backgroundColor: '#ffffff',
            '& .MuiSelect-select': { padding: '14px' }
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            height: 2,
            background: '#000000',
            opacity: 0.6
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            justifyContent: 'flex-start'
          }
        }
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            "& .MuiStepConnector-line": {
              minHeight: '2vh'
            }
          }
        }
      },
    }
  })
)

export default theme
