import { useEffect, useRef, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default function MapComponent(){
    const [map, setMap] = useState<google.maps.Map>();
    const ref = useRef<HTMLDivElement>();
    const [markerCluster, setMarkerClusters] = useState<MarkerClusterer>();
    const [marker, setMarker] = useState<{lat: number, lng: number} | undefined>();

    const lat = 9.862692915641654;
    const lng = -84.09328109629628;

    useEffect(()=>{
        if(ref.current && !map){
        setMap(new window.google.maps.Map(ref.current, {
            center: {lat: lat, lng: lng},
            zoom: 15,
        }))
        }
        
        if(map && !markerCluster){
            setMarker({lat: lat, lng: lng})
            setMarkerClusters(new MarkerClusterer({map, markers: [], }));
        }
    }, [map, markerCluster]);

    useEffect(()=> {
      if(marker && markerCluster){
        markerCluster.addMarker(
          new window.google.maps.Marker({
            position: {lat: lat, lng: lng},
            title: 'Municipalidad de Aserí'
          })
        )
      }
    }, [marker, markerCluster]);

    return (
      <>
        <div ref={ref as any} style={{height: "100%", width: "500px", minHeight:"500px"}} ></div>
      </>
    )
  }