import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch, publicFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";
import { NotificationObj } from "./NotificationInterfaces";
  
  export const createRequest = async (data: NotificationObj, userId: number) => {
    let responseData = {};

    const requestBody = data;

    const urlCreate = URL.REQUEST_NOTIFICATION_CREATE(userId);

    await publicFetch
  
      .post(urlCreate, requestBody, {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
  
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        if (error.response?.data?.code && error.response?.data?.code === '002'
          && error.response?.data?.message && error.response?.data?.message !== '')
          throw new Error(error.response.data.message);
        throw new Error(properties.ERROR_CREATING_RECORD);
      });
    return responseData;
  };
