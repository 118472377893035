import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import {Controller} from "react-hook-form";
import {DataItemProvince} from "../../types/auth/Register";



const ProvinceSelect = ({control, name, value, onChange, error, helperText, variant, register, data}: any) => {
  const {t} = useTranslation();

  return (
      <Grid item xs={12}>
        <FormControl sx={{width: "100%"}} error={error}>
          <InputLabel>{t("provincia")}</InputLabel>
          <Controller
              name={name}
              control={control}
              render={({field}) => <Select
                  {...register("province")}
                  name={name}
                  variant="standard"
                  label={t("provincia")}
                  value={value != 0 ? value : null}
                  onChange={onChange}
                  autoWidth
                  sx={{
                    backgroundColor: variant ? "transparent" : "#ffffff",
                    borderBottom: variant && "1px solid #8bc8bc",
                  }}
              >
                {data.map(function (provincia:DataItemProvince) {
                  return <MenuItem key={provincia.idProvince} value={provincia.idProvince}>
                    {provincia.nameProvince}
                  </MenuItem>;
                })}
              </Select>}
          />
          <FormHelperText sx={{color: error ? "red" : "initial"}}>
            {helperText}
          </FormHelperText>
        </FormControl>
      </Grid>
  );
};

export default ProvinceSelect;
