import { Status, Wrapper } from '@googlemaps/react-wrapper';
import MapComponent from '../../components/map/MapComponent';
import { Card, CardContent, Stack } from '@mui/material';

const GeographicLocation = () => {
  
  const render = (status: Status) => (<h1>{status}</h1>)

    return (
      <>    
          <Stack 
              direction="row" 
              justifyContent="center"
              alignItems="center"  
              sx={{ paddingTop: 15 }} >
            <Card sx={{ minWidth: 450 }}>
              <CardContent>
                <Wrapper apiKey={"AIzaSyCbdYgpg-eq682ETugR-lR0Nm_iTYVPTLk"} render={render}>
                  <MapComponent />
                </Wrapper>
              </CardContent>
            </Card>
          </Stack>
    </>
    )
}

export default GeographicLocation;