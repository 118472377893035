import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch, publicFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";
import { Complaint, ComplaintResponse } from "./ComplaintInterfaces";

export const getRequest = async (token: any, page: number,sort: string,filter: string,userId: number,categoryId: number, size?:any): Promise<ComplaintResponse> => {
 
    try {
      const response = await publicFetch.get(URL.REQUEST_COMPLAINT(page, size ?? UrlBase.registration_x_page, sort, filter, userId, categoryId),
      
      {

        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
  
  export const getRequestById = async (id: number): Promise<ComplaintResponse> => {
  
    try {
      const response = await publicFetch.get(URL.REQUEST_COMPLAINT_BY_ID(id));
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
  
  export const getRequestLastComplaintByUserId = async (userId: number): Promise<Complaint> => {
  
    try {
      const response = await publicFetch.get(URL.REQUEST_LAST_COMPLAINT_BY_USERID(userId));
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
  
  export const createRequest = async (data: Complaint) => {
    let responseData = {};

    const requestBody = data;

    const urlCreate = URL.REQUEST_COMPLAINT_CREATE(data.user && data.user.id ? data.user.id : 0);

    await publicFetch
  
      .post(urlCreate, requestBody, {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
  
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        if (error.response?.data?.code && error.response?.data?.code === '002'
          && error.response?.data?.message && error.response?.data?.message !== '')
          throw new Error(error.response.data.message);
        throw new Error(properties.ERROR_CREATING_RECORD);
      });
    return responseData;
  };
  
  export const updateRequest = async (data: any) => {
    let responseData = {};
    
    const requestBody = data;

    await publicFetch
      .put(URL.REQUEST_COMPLAINT_UPDATE(data.user ? data.user.id : 0), requestBody, {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
  
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        if (error.response?.data?.code && error.response?.data?.code === '002'
          && error.response?.data?.message && error.response?.data?.message !== '')
          throw new Error(error.response.data.message);
        throw new Error(properties.ERROR_UPDATING_RECORD);
      });
    return responseData;
  };
  
  export const changeStatusRequest = async (id: number, status: number) => {
    let responseData = {};
    
    const requestBody = status;

    await publicFetch
      .put(URL.REQUEST_COMPLAINT_CHANGE_STATUS(id), requestBody, {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      })
  
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        if (error.response?.data?.code && error.response?.data?.code === '002'
          && error.response?.data?.message && error.response?.data?.message !== '')
          throw new Error(error.response.data.message);
        throw new Error(properties.ERROR_UPDATING_RECORD);
      });
    return responseData;
  };
  
  
  export const deleteRequest = async (id: number) => {
    let responseData = {};
    await publicFetch
      .delete(URL.REQUEST_COMPLAINT_DELETE(id), {})
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        throw new Error(
          properties.ERROR_DELETING_RECORD
        );
      });
    return responseData;
  };