import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AserriLogo } from "../../components/svg/icons/SvgIcons";
import { useAuth } from "../../context/AuthContext";
import { useApp } from "../../hooks/useApp";
import { loginTypes } from "../../types/auth/Login";
import { CONSTANT } from "../../utils/Constants";
import Footer from "../../utils/Footer";
import Loading from "../../utils/Loading";
import MessageManager from "../../utils/MessageManager";
import DocumentTypeField from "../../utils/document/DocumentTypeField";
import DocumentTypeSelect from "../../utils/document/DocumentTypeSelect";
import { Content, LinkTo } from "../styles";

const Login: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        isLoading,
        successMsg,
        errorMsg,
        resetErrorMsg,
        resetSuccessMsg,
    } = useApp();

    const {
        loginValidation,
        showCode,
        showPassword,
        onSubmit,
        handleClickShowPassword,
        documentType,
        setDocumentType,
        handleDocumentTypeChange,
    } = useAuth();


    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<loginTypes>(
        {
            resolver: yupResolver(loginValidation),
        },
    );

    return (
        <>
            {errorMsg && (
                <MessageManager
                    type={CONSTANT.ERROR_MSG}
                    msg={errorMsg}
                    details=""
                    callback={resetErrorMsg}
                />
            )}
            {successMsg && (
                <MessageManager
                    type={CONSTANT.SUCCESS_MSG}
                    msg={successMsg}
                    details=""
                    callback={resetSuccessMsg}
                />
            )}
            {isLoading && <Loading />}

            <Content
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ mt: -6, py: 4 }}
            >
                <Box>
                    <AserriLogo />
                </Box>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: "430px",
                        maxWidth: "100%",
                        position: "relative",
                        margin: "auto",
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: "center",
                            fontSize: "1.50rem",
                            color: theme.palette.info.dark,
                            // mt: `${theme.spacing(2)}!important`,
                            mb: `${theme.spacing(2)}!important`,
                        }}
                    >
                        {t("login")}
                    </Typography>
                </Stack>

                <Paper
                    sx={{
                        width: "430px",
                        maxWidth: "100%",
                        borderRadius: "25px",
                        background: "#F8F5F3",
                        marginBottom: `${theme.spacing(2)}!important`,
                        padding: {
                            xs: theme.spacing(6),
                        },
                    }}
                >
                    <form>

                        <Grid container >
                            <DocumentTypeSelect
                                control={control}
                                value={documentType}
                                onChange={(e: any) => handleDocumentTypeChange(e.target.value)}
                                setDocumentType={setDocumentType}
                                disabled={showCode}
                            />

                            <Grid item xs={12} mt={2}>
                                <DocumentTypeField
                                    control={control}
                                    documentType={documentType}
                                    error={!!errors.document}
                                    helperText={errors.document?.message}
                                    disabled={showCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name={"password"}
                                    control={control}
                                    render={() => (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label={t("password")}
                                            type={showPassword ? "text" : "password"}
                                            variant="standard"
                                            disabled={showCode}
                                            {...register("password")}
                                            error={errors.password && Boolean(errors.password)}
                                            helperText={errors.password && errors.password.message}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={handleClickShowPassword}>
                                                            {!showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} hidden={!showCode}>
                                <Controller
                                    name={"code"}
                                    control={control}
                                    render={() => (
                                        <TextField
                                            fullWidth
                                            label={t("code")}
                                            size="small"
                                            type="text"
                                            variant="standard"
                                            {...register("code")}
                                            error={!!errors.code}
                                            helperText={!!errors.code && errors.code?.message}
                                            inputProps={{ autoComplete: "off" }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-name="update"
                                    name="update"
                                    fullWidth
                                    onClick={handleSubmit(onSubmit)}
                                    sx={{
                                        borderRadius: "0.25rem",
                                        mt: 3,
                                    }}
                                >
                                    {t("login")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid item xs={12}>
                        <Typography align="center" sx={{ mt: 2 }}>
                            {t("notHaveAccount")} <LinkTo to="register">{t("registerHere")}</LinkTo>
                        </Typography>
                    </Grid>
                    <Typography align="center" sx={{ mt: 4 }} variant="body1">
                        <LinkTo sx={{ color: theme.palette.text.secondary }}
                            to="rememberpassword">
                            {t("forgot")}
                        </LinkTo>
                    </Typography>
                </Paper>
                <Footer />
            </Content>
        </>
    );
};

export default Login;
