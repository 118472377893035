import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { data as Role } from "../../resources/data/data";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { valideAvailablePassword } from "../../services/users/UserService";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateUser {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone: string;
  status: string;
  roleID: {
    id?: number;
    role_name?: string;
  }[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const UpdateUserPassModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const { t } = useTranslation();
  //Validación de datos
  const validation = Yup.object().shape({
    password: Yup.string()
      .required("Campo es requerido")
      .test("availablePassword", "No puedes usar la misma contraseña", (value) => {
        return !availablePassword;
      }),
  });
  const defaultValues = {
    id: data.id ? data.id : 0,
    email: data.email ? data.email : "",
    firstName: data.firstName ? data.firstName : "",
    lastName: data.lastName ? data.lastName : "",
    password: data.password ? data.password : "",
    phone: data.phone ? data.phone : "",
    status: data.status ? data.status : "",
    roleID: data.roleID
      ? Role.userRole.find((f: any) => f.role_name === data.roleID)
      : "",
    activationCode: data.activationCode ? data.activationCode : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    //resolver: yupResolver(validation),
    mode: "onChange",
  });

  const [availablePassword, setAvailablePassword] = useState(false);

  const validatePassword = async (password: string) => {
    try {
      const isAvailable: boolean = await valideAvailablePassword(data.id,password);
      setAvailablePassword(isAvailable);
    } catch (error) {
      console.error("Error al validar la contraseña:", error);
    }
  };

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
      {t("updatePassword")}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("password")}
                    type="password"
                    variant="standard"
                    value={value}
                    autoComplete="new-password"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("password")}
                    onChange={(e) => {
                      onChange(e);
                      validatePassword(e.target.value);
                    }}
                    error={availablePassword || !!errors.password}
                    helperText={
                      (availablePassword && t("passwordSameAsPrevious")) ||
                      (errors.password && errors.password.message)
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
         {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default UpdateUserPassModal;
