import { Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { AccountStatementsSvgIcon, InternalDirectorySvgIcon, LocationSvgIcon, PendingQueriesSvgIcon } from './Icons'; // Ajusta la ruta según la estructura de tu proyecto

interface CardWidgetProps {
    title: string;
    icon: any;
    iconColor?: string;
    onTap?: () => void;
}

const CardContainer = styled(Card)({
    margin: 16,
    borderRadius: 10.76,
    width: '70vw',  
    maxWidth: '200px', 
    minWidth: '200px', 
    height: '70vw',  
    maxHeight: '200px', 
    minHeight: '200px', 
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

const IconContainer = styled('div')<{ iconColor?: string }>(({ iconColor }) => ({
    backgroundColor: iconColor || '#ffffff',

    borderRadius: '10.76px 10.76px 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    color: 'transparent',
}));

const TextContainer = styled('div')({
    backgroundColor: '#ffffff',
    borderRadius: '0 0 10.76px 10.76px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30%',
    width: '100%',
});

const CardWidget: React.FC<CardWidgetProps> = ({ title, icon, iconColor, onTap }) => {
    const renderIcon = () => {
        switch (icon) {
            case 'account_statements':
                return <AccountStatementsSvgIcon color="transparent" />;
            case 'internal_directory':
                return <InternalDirectorySvgIcon color="transparent" />;
            case 'location':
                return <LocationSvgIcon color="transparent" />;
            case 'pending_queries':
                return <PendingQueriesSvgIcon color="transparent" />;
            default:
                return null;
        }
    };

    return (
        <CardContainer onClick={onTap}>
            <IconContainer iconColor={iconColor}>
                {renderIcon()}
            </IconContainer>
            <TextContainer>
                <Typography variant="h6" align="center">
                    {title}
                </Typography>
            </TextContainer>
        </CardContainer>
    );
};

export default CardWidget;
