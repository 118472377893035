import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { getRequest } from "../../services/roles/RoleService";
import { activateUser, valideAvailableUser } from "../../services/users/UserService";
import { formSchemaOptionsName } from "../../types/Utils";
import { properties } from "../../utils/Properties_es";

interface ValidationResult {
  exists: boolean;
  // otras propiedades si las hay
}

interface AddOrUpdateUser {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  status: string;
  roleID: {
    id?: number;
    name?: string;
  }[];
  birthDate: Date,
  canton: string;
  district: string;
  identification: number;
  identificationType: {
    id?: number;
    name?: string;
  }[];
  province: string;
  gender: {
    id?: string;
    name?: string;
  }[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  refreshData: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  refreshData,
  modalType,
  roleData,
}: DialogProps) => {
  const { t } = useTranslation();
  const [filteredRoles, setFilteredRoles] = useState<any>([]);
  const [availableEmail, setAvailableEmail] = useState(false);
  //Validación de datos
  const validation = Yup.object().shape({
    email: Yup.string()
      .required(t("fieldRequired"))
      .email(t("invalidEmail")),
    firstName: Yup.string().required(t("fieldRequired")),
    lastName: Yup.string().required(t("fieldRequired")),
    /*password: Yup.string().is(modalType, {
      is: () => {
        if (modalType === "create") return true;
      },
      then: Yup.string().required(t("fieldRequired")),
    }),*/
    phone: Yup.string().required(t("fieldRequired")),
    status: Yup.string(),
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError(t("fieldRequired")),
  });
  const { setLoading, setErrorMsg, isAdmin, setSuccessMsg} = useApp();

  const defaultValues = {
    id: data ? data.id : 0,
    email: data ? data.email : "",
    firstName: data ? data.firstName : "",
    lastName: data ? data.lastName : "",
    password: data ? data.password : "",
    phone: data ? data.phone : "",
    status: data ? data.status : "",
    /* roleID: data.roleID
      ? Role.userRole.find((f: any) => f.role_name === data.roleID)
      : "", */
    roleID: data ? roleData.find((f: any) => f.name === data.userRole) : [],
    activationCode: data ? data.activationCode : "",
    birthDate: data ? data.birthDate : null,
    canton: data ? data.canton : "",
    district: data ? data.district : "",
    identification: data ? data.identification : 0,
    identificationType: data ? data.identificationType : 0,
    province: data ? data.province : "",
    gender: data ? data.sex : "M",

  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    //resolver: yupResolver(validation),
    mode: "onChange",
  });

  const handleActiveUser = async (data: any) => {
    setLoading && setLoading(true);
    try {
      let dataActiveUser = await activateUser(
        data.activationCode,
        data.email
      );
      if (!dataActiveUser) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      // await handleFetchByID(formData.id);

      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(t("userHasBeenActivated"));
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
    refreshData(0, "");
    cancelModal();
  };

  const validateEmail = async (email: string) => {
    // Expresión regular para validar el formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    try {
      // Verificar si el correo electrónico tiene el formato correcto
      if (!emailRegex.test(email)) {
        // El correo electrónico no tiene el formato correcto
        setAvailableEmail(false);
        return;
      }

      // El correo electrónico tiene el formato correcto, continuar con la validación
      const isAvailable: boolean = await valideAvailableUser(email);
      setAvailableEmail(isAvailable);
    } catch (error) {
      console.error("Error al validar el correo electrónico:", error);
    }
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? t("updateUser")
          : t("addUser")}
        {data && data.status === 6 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActiveUser(data)}
          >
            {t("activateUser")}
          </Button>
        )}
        {data && data.status === 2 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleActiveUser(data)}
          >
            {t("activateUser")}
          </Button>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("name")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("lastName")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("phone")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="phone"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"email"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      value={value}
                      autoComplete="email"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("email")}
                      onChange={(e) => {
                        setValue("email", e.target.value);
                        validateEmail(e.target.value);
                      }}
                      error={availableEmail || (errors.email && Boolean(errors.email))}
                      helperText={
                        (availableEmail && t("emailAlreadyExists")) ||
                        (errors.email && errors.email.message)
                      }
                    />
                  )}
                />
              </Grid>
            )}
            {modalType === "create" && (
              <Grid item xs={12}>
                  <Controller
                      name="birthDate"
                      control={control}
                      render={({ field }) => (
                          <TextField
                              {...field}
                              label={t("birthDate")}
                              {...register("birthDate")}
                              fullWidth
                              variant="standard"
                              error={!!errors.birthDate}
                              helperText={errors.birthDate?.message}
                              type="date"
                              placeholder="dd/mm/yyyy"
                          />
                      )}
                  />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name={"province"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("provincia")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="province"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("province")}
                    error={errors.province && Boolean(errors.province)}
                    helperText={errors.province && errors.province.message}
                  />
                )}
              />
            </Grid>            
            <Grid item xs={12}>
              <Controller
                name={"canton"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("canton")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="canton"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("canton")}
                    error={errors.canton && Boolean(errors.canton)}
                    helperText={errors.canton && errors.canton.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"district"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label={t("distrito")}
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="district"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("district")}
                    error={errors.district && Boolean(errors.district)}
                    helperText={errors.district && errors.district.message}
                  />
                )}
              />
            </Grid>  
            {modalType === "create" && (  
              <Grid item xs={12}>
                <Controller
                  name={"identification"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("identification")}
                      type="text"
                      variant="standard"
                      value={value}
                      autoComplete="identification"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("identification")}
                      error={errors.identification && Boolean(errors.identification)}
                      helperText={errors.identification && errors.identification.message}
                    />
                  )}
                />
              </Grid>
            )}
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"identificationType"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={[
                        { id: 1, name: t("cedula") },
                        { id: 2, name: t("dimex") },
                      ]}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={t("selectDocument")}
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.identificationType}
                          helperText={errors.identificationType && errors.identificationType.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"gender"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={[
                        { id: "M", name: t("male") },
                        { id: "F", name: t("female") },
                        { id: "O", name: t("other") },
                      ]}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={t("gender")}
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.gender}
                          helperText={errors.gender && errors.gender.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            {isAdmin && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="roleID"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={roleData}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("profile")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.roleID}
                            helperText={errors.roleID && errors.roleID.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {!isAdmin && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="roleID"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={roleData}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={t("profile")}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={!!errors.roleID}
                            helperText={errors.roleID && errors.roleID.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("password")}
                      type="password"
                      variant="standard"
                      value={value}
                      autoComplete="password"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateUserModal;
