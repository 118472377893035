/* eslint-disable @typescript-eslint/no-unused-vars */
import { Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceAccess from "../../components/security/ResourceAccess";
import { PenIcon, PlusIcon, TrashIcon } from "../../components/svgicons/SvgIcons";
import DeleteModal from "../../components/util/DeleteModal";
import FancyTablePagination from "../../components/util/FancyTablePagination";
import AddOrUpdateInternalDirectoryModal from "../../components/util/internalDirectory/AddOrUpdateInternalDirectoryModal";
import { useApp } from "../../hooks/useApp";
import {
  createRequest,
  deleteRequest,
  getRequest,
  updateRequest
} from "../../services/internalDirectory/InternalDirectoryService";

const InternalDirectory = () => {
  const { t } = useTranslation();
  const {
    authInfo,
    isAdmin,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  const theme = useTheme();
  const [internalDirectoryData, setInternalDirectoryData] = useState<any>([]);
  const initForm = {
    id: 0,
    status: 0,
    name: "",
    company: 0,
    user: null
  };

  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();
  const [page, setPage] = useState(0);
  const [preFilter, setPreFilter] = useState<string | undefined>('');
  const [internalDirectoryObject, setInternalDirectoryObject] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, preFilter);
    };
    dataInit();
  }, [authInfo]);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage: number,
    filter?: string,
    applyRole?: any
  ) => {
    setLoading && setLoading(true);
    setInternalDirectoryData([]);
    try {
      let data = await getRequest(filter, currentPage);
      if (data) {
        setInternalDirectoryData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(t("errorCreatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("successfullyCreatedRecord"));
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        userModified: authInfo?.username,

      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(t("errorUpdatingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("recordUpdatedCorrectly"));
      //call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(t("errorDeletingRecord"));
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(t("recordDeletedSuccessfully"));

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    if (modalAction === "update") {
      object = internalDirectoryData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = internalDirectoryData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "view") {
      object = internalDirectoryData.content.find((p: any) => p.id === parseInt(id));
      if (object)
        setInternalDirectoryObject(object);
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    const customPage = newPage - 1;
    setPage(customPage);
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={8}>
        <Grid item md={12} sm={6} xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ paddingTop: 5 }}
          >
            <Box
              sx={{
                paddingTop: 2,
                paddingLeft: 2
              }}
              display="flex"
              alignContent="center"
              alignItems="center"
              gap={4}
            >
              <ResourceAccess isCode={true} pathOrCode={'DIRECTORY:WRITE'}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  data-name="create"
                >
                  {t('addDirectory')}
                  <PlusIcon sx={{ ml: 1 }} />
                </Button>
              </ResourceAccess>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  paddingTop: 2
                }}
              >
              </Stack>
              <Stack
                sx={{
                  paddingTop: 2,
                  paddingRight: 2
                }}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <TextField
                  placeholder={t('searchFilter')}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "0.2rem 0 0 0.2rem",
                    },
                    "& fieldset": {
                      borderRightWidth: "0",
                    },
                  }}
                  value={preFilter}
                  onChange={(e: any) => {
                    setPreFilter(e.target.value);
                    if (e.target.value === "") {
                      handleFetchData(0, "");
                    }
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleApplyFilter}
                  sx={{
                    borderRadius: "0 0.2rem 0.2rem 0!important",
                    padding: "1.5px 16px!important",
                    minWidth: '20px !important',
                  }}
                >
                  {t("search")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack
            direction="column"
            sx={{}}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('address')}</TableCell>
                    <TableCell align="center">{t('department')}</TableCell>
                    <TableCell align="center">{t('user')}</TableCell>
                    <TableCell align="center">{t('email')}</TableCell>
                    <TableCell align="center">{t('directNumber')}</TableCell>
                    <TableCell align="center">{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {internalDirectoryData &&
                    internalDirectoryData.content &&
                    internalDirectoryData.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {row.address.name}
                        </TableCell>
                        <TableCell align="center">{row.department.name}</TableCell>
                        <TableCell align="center">{row.user}</TableCell>
                        <TableCell align="center">{row.mail}</TableCell>
                        <TableCell align="center">{row.directNumber}</TableCell>
                        <TableCell align="center">

                          <ResourceAccess isCode={true} pathOrCode={'DIRECTORY:WRITE'}>
                            <Tooltip title="Editar" placement="top-start">
                              <IconButton
                                aria-label="edit"
                                component="label"
                                color="primary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                onClick={handleOpenModal}
                                data-name="update"
                                data-id={row.id}
                              >
                                <PenIcon />
                              </IconButton>

                            </Tooltip>
                          </ResourceAccess>

                          <Tooltip title="Ver directorio" placement="top-start">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              color="secondary"
                              sx={{
                                "&:hover": {
                                  color: theme.palette.secondary.dark,
                                },
                              }}
                              onClick={handleOpenModal}
                              data-name="view"
                              data-id={row.id}
                            >
                              <Visibility />
                            </IconButton>

                          </Tooltip>

                          <ResourceAccess isCode={true} pathOrCode={'DIRECTORY:WRITE'}>
                            <Tooltip title="Eliminar" placement="top-start">
                              <IconButton
                                aria-label="trash"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={row.id}
                                onClick={handleOpenModal}
                                data-name="delete"
                              >
                                <TrashIcon />
                              </IconButton>
                            </Tooltip>
                          </ResourceAccess>



                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={internalDirectoryData?.content?.length > 0 ? internalDirectoryData?.content?.length : 0}
              rowsPerPage={internalDirectoryData.size}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalElements={internalDirectoryData.totalElements}
              totalPages={internalDirectoryData.totalPages}
            />
          </Stack>
        </Grid>
      </Grid>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
          >
            <AddOrUpdateInternalDirectoryModal
              data={modalData?.modalObject}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("delete")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  {t("cancel")}
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
      {modalData?.modalType === "view" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('internalDirectory')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>{t('address')}:</b> {internalDirectoryObject?.address?.name ? internalDirectoryObject.address.name : ''}
              <br />
              <br />
              <b>{t('department')}:</b> {internalDirectoryObject?.department?.name ? internalDirectoryObject.department.name : ''}
              <br />
              <br />
              <b>{t('user')}:</b> {internalDirectoryObject?.user ? internalDirectoryObject.user : ''}
              <br />
              <br />
              <b>{t('email')}:</b> {internalDirectoryObject?.mail ? internalDirectoryObject.mail : ''}
              <br />
              <br />
              <b>{t('directNumber')}:</b>{internalDirectoryObject?.directNumber ? internalDirectoryObject.directNumber : ''}
              <br />
              <br />
              <b>{t('extension')}:</b>{internalDirectoryObject?.extension ? internalDirectoryObject.directNumber : ''}
              <br />
              <br />
              <b>{t('analogicNumber')}:</b>{internalDirectoryObject?.analogicNumber ? internalDirectoryObject.analogicNumber : ''}
              <br />
              <br />
              <b>{t('analogicExtension')}:</b>{internalDirectoryObject?.analogicExtension ? internalDirectoryObject.analogicExtension : ''}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelModal} autoFocus>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InternalDirectory;