import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import esNs1 from './lang/es/ns1.json';

export const defaultNS = 'ns1';

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'es',
  defaultNS,
  resources: {
    es: {
      ns1: esNs1,
    },
    // en: {
    //   ns1: enNs1
    // },

  },
});

export default i18next;
