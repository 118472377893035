import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useApp } from "../../hooks/useApp";
import { createRequest } from "../../services/notification/NotificationService";
import { properties } from "../../utils/Properties_es";
import { NotificationObj } from "../../services/notification/NotificationInterfaces";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { t } from "i18next";
import ListCheckUser from "../../components/util/ListCheckUser";
import { object } from "yup";

const Notification = () => {
    const [body, setBody] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [all, setAll] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [listSelectUser, setListSelectUser] = useState<[]>([]);
    const [userSelect, setUserSelect] = useState('');
    const [typeNotification, setTypeNotification] = useState('1');

    const {
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authInfo
    } = useApp();

    useEffect(() => {
        // session console.log('authInfo complaint',authInfo)
    }, [authInfo]);


    /**
     * Metodo actualiza la variables
     */
    const onTextBody = (e: any) => setBody(e.target.value);
    const onTextTitle = (e: any) => setTitle(e.target.value);
    const onTextEmail = (e: any) => setEmail(e.target.value);

    /**
     * Metodo limpia la variable textComplait
     */
    const handleReset = () => {
        setBody("");
        setTitle("");
        setEmail("");
        setUserSelect("");
        setTypeNotification("1");
        setListSelectUser([]);
        setAll(false);
    };

    /**
     * Metodo limpia la variable textComplait
     */
    const createObject = (): NotificationObj => {

        const notification: NotificationObj = {
            title: title,
            body: body,
            notificationPush: (typeNotification == '2' || typeNotification == '3'),
            notificationMail: (typeNotification == '1' || typeNotification == '3'),
            email: email,
            users: listSelectUser,
            all: all
        }

        return notification;
    };

    /**
     * Metodo crear denuncia
     */
    const handleSubmit = async () => {
        if (!body || !title) {
            setErrorMsg && setErrorMsg(properties.VALIDATE_REQUIRED_FIELDS);
            return;
        }

        setLoading && setLoading(true);
        try {
            const data: NotificationObj = createObject();
            let createData = await createRequest({
                ...data
            }, 1);

            if (!createData) {
                setErrorMsg && setErrorMsg(properties.ERROR_SEND_DATA);
                setLoading && setLoading(false);
                return;
            }

            handleReset();
            setLoading && setLoading(false);
            setSuccessMsg &&
                setSuccessMsg(properties.SUCCESSFULLY_SEND_DATA);
        } catch (error: any) {
            setErrorMsg && setErrorMsg(error.message);
            setLoading && setLoading(false);
        }
    };

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAll(event.target.checked);
    };

    /**
     * Metodo encargado de cerrar el modal
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Metodo encargado de la apertura de modal
     */
    const handleOpenModal = async (event: any) => {
        event.preventDefault();
        setOpen(true);
    };



    /**
     * Efecto para actualizar vista usuario
     */
    useEffect(() => {
        setUserSelect('');
        const dataUpadte = async () => {
            let text: string = '';
            listSelectUser.forEach((object: any) => {
                if (text == '')
                    text = `[${object.name}  -  ${object.email}]`
                else
                    text += `, [${object.name}  -  ${object.email}]`
            });
            setUserSelect(text);
        };
        dataUpadte();
    }, [listSelectUser]);



    const handleChange = (event: SelectChangeEvent) => {
        setTypeNotification(event.target.value as string);
    };

    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={8}
            >
                <Grid item xs={10}>
                    <Stack
                        direction="column"
                        sx={{ paddingTop: 10 }} >
                        <FormControl fullWidth sx={{ m: 1, width: '85%' }} >
                            <TextField
                                id="standard-multiline-static-complaint"
                                label="Asunto"
                                onChange={onTextTitle}
                                value={title}
                                variant="outlined"
                            />
                        </FormControl>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <FormControl sx={{ m: 1, width: '48%' }} >
                                <TextField
                                    disabled={all}
                                    id="standard-multiline-static-complaint"
                                    label="Correo electrónico"
                                    onChange={onTextEmail}
                                    value={email}
                                    variant="outlined"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '48%' }}>
                                <InputLabel id="demo-simple-select-label">Tipo de notificación</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeNotification}
                                    label="Tipo de notificación"
                                    onChange={handleChange}
                                    variant="outlined"
                                >
                                    <MenuItem value={1}>Correo Electrónico</MenuItem>
                                    <MenuItem value={2}>Notificación Push</MenuItem>
                                    <MenuItem value={3}>Ambos</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <FormControl fullWidth sx={{ m: 1, width: '85%' }} variant="outlined" >
                                <TextField
                                    label="Usuario seleccionados (campo informativo)"
                                    id="outlined-usuarios-seleccionados"
                                    value={userSelect}
                                />
                                <FormHelperText id="outlined-weight-helper-text">Usuarios seleccionados {listSelectUser.length}</FormHelperText>
                            </FormControl>

                            <Tooltip title="seleccionar usuarios" placement="top-start">
                                <IconButton
                                    aria-label="select user"
                                    disabled={all}
                                    color="primary"
                                    onClick={handleOpenModal}
                                >
                                    <PersonAddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <FormControlLabel sx={{ m: 1, width: '25ch' }}
                            control={
                                <Checkbox checked={all} onChange={handleChangeAll} name="gilad" />
                            }
                            label="Todos los usuarios"
                        />
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                id="standard-multiline-static-complaint"
                                label="Mensaje"
                                onChange={onTextBody}
                                value={body}
                                multiline
                                rows={6}
                                variant="outlined"
                            />
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={10}
                >
                    <Stack direction="row">
                        <Button
                            disabled={body === '' && title === '' && (email === '' || listSelectUser.length > 0 || all)}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Enviar
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('selectUserNotification')}
                </DialogTitle>
                <DialogContent>
                    <ListCheckUser listSelectUser={listSelectUser} setListSelectUser={setListSelectUser}></ListCheckUser>
                    <DialogContentText id="alert-dialog-description">
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Notification;
