import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { rememberPassword } from "../../types/auth/RememberPassword";



import { useTranslation } from "react-i18next";
import { AserriLogo } from "../../components/svg/icons/SvgIcons";
import { useAuth } from "../../context/AuthContext";
import { CONSTANT } from "../../utils/Constants";
import Footer from "../../utils/Footer";
import Loading from "../../utils/Loading";
import MessageManager from "../../utils/MessageManager";
import { Content, LinkTo } from "../styles";

const RememberPassword: FC = () => {

  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isLoading,
    successMsg,
    errorMsg,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();

  const {
    onSubmitRecoveryPassword,
  } = useAuth();

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Email es requerido")
      .email("Email es invalido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<rememberPassword>({
    resolver: yupResolver(validation),
  });

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}

      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: -6, py: 4 }}
      >
        <AserriLogo />
        <Stack
          flexDirection={"row"}
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "430px",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: "1.50rem",
              color: theme.palette.info.dark,
              // mt: `${theme.spacing(2)}!important`,
              mb: `${theme.spacing(2)}!important`,
            }}
          >
            {t("forgotPassword")}
          </Typography>
          {/* <FormControl
            sx={{ m: 1, minWidth: 60, position: "absolute", right: 0 }}
          >
            <Select
              value={language}
              autoWidth
              sx={{
                paddingLeft: theme.spacing(4),
                "& .MuiSelect-select": {
                  minWidth: {
                    xs: "30px!important",
                    md: "90px!important",
                  },
                },
              }}
              onChange={(e: any) =>
                handleLangChange && handleLangChange(e.target.value)
              }
            >
              <MenuItem value="en">{t("english")}</MenuItem>
              <MenuItem value="es">{t("spanish")}</MenuItem>
            </Select>
          </FormControl> */}
        </Stack>
        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            background: "#F8F5F3",
            marginBottom: `${theme.spacing(3)}!important`,
            padding: {
              xs: theme.spacing(6),
              // md: `${theme.spacing(8)} ${theme.spacing(12)}`,
            },
          }}
        >
          <form>
            <Grid container spacing={4}>
              {/* <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontSize: "1.75rem",
                    color: theme.palette.primary.main,
                    // mt: `${theme.spacing(2)}!important`,
                    // mb: `${theme.spacing(2)}!important`,
                  }}
                >
                  {t("recoverPass")}
                </Typography>
              </Grid> */}

              <Grid item xs={12}>
                <Controller
                  name={"username"}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label={t("email")}
                      type="email"
                      variant="standard"
                      autoComplete="username"
                      {...register("username")}
                      error={errors.username && Boolean(errors.username)}
                      helperText={errors.username && errors.username.message}
                      sx={{
                        "& .MuiInput-root:before": {
                          borderBottomColor: "#8bc8bc!important",
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  data-name="update"
                  name="update"
                  fullWidth
                  onClick={handleSubmit(onSubmitRecoveryPassword)}
                  sx={{
                    borderRadius: "0.25rem",
                    mt: 3,
                  }}
                >
                  {t("send")}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Typography align="center" sx={{ mt: 6 }} variant="body1">
            <LinkTo sx={{ color: theme.palette.text.secondary }}
              to="/">
              {t("returnToLogin")}
            </LinkTo>
          </Typography>
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default RememberPassword;
