import { URL } from "../../utils/UrlConstants";
import {publicFetch} from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";

export const getRequest = async () => {
  let responseData = {};
  await publicFetch
    .get(
      URL.REQUEST_ROLE(null)
    )
    .then(async (response) => {
      responseData = await response.data.content;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const findByIdRequest = async (id:any) => {
  let responseData = {};
  await publicFetch
    .get(URL.REQUEST_ROLE(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequest = async (data:any) => {
  let responseData = {};
  
  const requestBody = {
    id: data.id,
    name: data.name,
    description: data.description,
    adminLogin: true,
    clientLogin: true,
    userCreated: data.userCreated
  };
  await publicFetch
    
    .post(URL.REQUEST_ROLE_CREATE, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
   
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};

export const updateRequest = async (data:any) => {
  let responseData = {};  
  const requestBody = {
    id: data.id,
    name: data.name,
    description: data.description,
    adminLogin: data.adminLogin,
    clientLogin:data.clientLogin,
  };
  await publicFetch
    .put(URL.REQUEST_ROLE_UPDATE, JSON.stringify(requestBody),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRequest = async (id:any) => {
  let responseData = {};
  await publicFetch
    .delete(URL.REQUEST_ROLE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
