import {
  FromBracketIcon,
  GearIcon,
  HouseIcon,
  MapIcon,
} from "../components/svgicons/SvgIcons";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: (
      <HouseIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "home",
    permissions: ["is_superadmin","is_admin","is_user"]
  },
  {
    label: "Tramites",
    icon: <TextSnippetIcon />,
    to: null,
    expand: true,
    permissions: ["is_superadmin","is_admin","is_user"],
    children: [
      {
        label: "Denuncias / Sugerencias",
        to: "complaint",
        permissions: ["is_superadmin","is_user","is_admin"],
      },
      {
        label: "Lista de denuncias / sugerencias",
        to: "complaint-list",
        permissions: ["is_superadmin","is_user","is_admin"],
      },
      {
        label: "Pagos en linea",
        to: "https://comercio.ifam.go.cr/aserri",
        permissions: ["is_superadmin","is_user","is_admin"],
        external: true,
      },
      {
        label: "Consulta Pendientes",
        to: "balance",
        permissions: ["is_superadmin","is_user","is_admin"],
      },
    ]
  },
  {
    label: "Configuración",
    icon: (
      <GearIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
    ),
    to: null,
    expand: true,
    permissions: ["is_superadmin","is_admin","is_user"],
    children: [
      {
        label: "Usuarios",
        to: "settinguser",
        permissions: ["is_superadmin","is_admin"],
      },
      {
        label: "Perfiles",
        to: "setting-roles",
        permissions: ["is_superadmin"],
      },
      {
        label: "Permisos",
        to: "setting-authority",
        permissions: ["is_superadmin"],
      },
      {
        label: "Directorio Interno",
        to: "internal-directory",
        permissions: ["is_superadmin","is_admin","is_user"],
      },
      {
        label: "Categorías",
        to: "categories",
        permissions: ["is_superadmin"],
      },
    ]
  },
  {
    label: "Ubicación",
    icon: (
      <MapIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
    ),
    to: 'geographic-location',
    expand: false,
    permissions: ["is_superadmin","is_admin","is_user"],
  },
  {
    label: "Notificaciones",
    icon: <TextSnippetIcon />,
    to: 'notification',
    expand: false,
    permissions: ["is_superadmin"],
  },
  {
    label: "Cerrar sesión",
    icon: <FromBracketIcon />,
    to: null,

    permissions: ["is_superadmin", "is_user","is_admin"],
  },
];