import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";

import { AuthLayout } from '../components/security/AuthLayout';
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from '../pages/auth/Login';
import RememberPassword from "../pages/auth/RememberPassword";
import DashboardHome from "../layouts/DashboardHome";
import Home from "../pages/home/Home";
import { getAuthData } from "../utils/LocalStorageManager";
import Register from "../pages/auth/register/Register";
import ComplaintForm from "../pages/complaint/ComplaintForm";
import ComplaintList from "../pages/complaint/ComplaintList";
import ActiveRegister from "../pages/session/ActiveRegister";
import GeographicLocation from "../pages/geographicLocation/GeographicLocation";
import InternalDirectory from "../pages/internalDirectory/InternalDirectory";
import Categories from "../pages/categories/Categories";
import Notification from "../pages/notification/Notification";
import SettingAuthority from "../pages/SettingAuthority";
import BalanceQuery from "../pages/balance/BalanceQuery";
import SettingRoles from "../pages/SettingRoles";
import React from "react";
import SettingUser from "../pages/SettingUser";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import CancelSubscription from "../pages/auth/CancelSubscription";

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}

    >
      <Route path="/" element={<Login />} />
      <Route path="/rememberpassword" element={<RememberPassword />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/forgot-password/:code/:email"
        element={<ForgotPassword />}
      />
      <Route
        path="/active-register/:code/:email"
        element={<ActiveRegister />}
      />


      <Route path="/dashboard" element={<DashboardHome />}>
        <Route path="home" element={<Home />} />
        <Route path="complaint" element={<ComplaintForm />} />
        <Route path="complaint-list" element={<ComplaintList />} />
        <Route path="geographic-location" element={<GeographicLocation />} />
        <Route path="internal-directory" element={<InternalDirectory />} />
        <Route path="categories" element={<Categories />} />
        <Route path="notification" element={<Notification />} />
        <Route path="setting-authority" element={<SettingAuthority />} />
        <Route path="setting-roles" element={<SettingRoles />} />
        <Route path="balance" element={<BalanceQuery />} />
        <Route path="settinguser" element={<SettingUser />} />
        <Route
            path="authoritiesbyrole/:roleId"
            element={<SettingAuthorityByRoleManagement />}
        />

      </Route>

      <Route path="/cancelSubscription" element={<CancelSubscription />} />
      
    </Route>
  )
)
