import { CONSTANT } from "./Constants";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",
  CREATEUSER_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/createForm",

  // Get Documents
  REQUEST_DOCUMENTS_FORM:
    CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + "/documents/list",

  /** 
   * MICRO MAINTENANCE
   */
    
    /** COMPLAINTS URLS*/
    REQUEST_COMPLAINT: (page: number, size: number,sort: string,filter: string,userId: number,categoryId: number,) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/?page=${page}&size=${size}&sort=${sort}&filter=${filter}&userId=${userId}&categoryId=${categoryId}`,
    REQUEST_COMPLAINT_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/${id}`,
    REQUEST_LAST_COMPLAINT_BY_USERID: (idUser: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/lastComplaint/${idUser}`,
    REQUEST_COMPLAINT_CREATE: (idUser: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/${idUser}`,
    REQUEST_COMPLAINT_UPDATE: (idUser: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/${idUser}`,
    REQUEST_COMPLAINT_DELETE: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/${id}`,
    REQUEST_COMPLAINT_CHANGE_STATUS: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/complaint/changeStatus/${id}`,
    
    /** DEPARTMENT URLS*/
    REQUEST_DEPARMENT: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/`,
    // REQUEST_DEPARTMENT_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/${id}`,
    // REQUEST_DEPARTMENT_CREATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/create`,
    // REQUEST_DEPARTMENT_UPDATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/updated`,
    // REQUEST_DEPARTMENT_DELETE: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/${id}`,
    
    /** ADDRESS URLS*/
    REQUEST_ADDRESS: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/address/`,
    // REQUEST_ADDRESS_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/${id}`,
    // REQUEST_ADDRESS_CREATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/create`,
    // REQUEST_ADDRESS_UPDATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/updated`,
    // REQUEST_ADDRESS_DELETE: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/department/${id}`,
    
    /** INTERNAL_DIRECTORY URLS*/
    REQUEST_INTERNAL_DIRECTORY: (page: number, size: number, filter: any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/internalDirectory/?page=${page}&size=${size}&filter=${filter}`,
    REQUEST_INTERNAL_DIRECTORY_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/internalDirectory/${id}`,
    REQUEST_INTERNAL_DIRECTORY_CREATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/internalDirectory/create`,
    REQUEST_INTERNAL_DIRECTORY_UPDATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/internalDirectory/updated`,
    REQUEST_INTERNAL_DIRECTORY_DELETE: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/internalDirectory/${id}`,
    
    /** CATEGORIES URLS*/
    REQUEST_CATEGORIES: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/`,
    REQUEST_CATEGORIES_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/${id}`,
    REQUEST_CATEGORIES_CREATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/`,
    REQUEST_CATEGORIES_UPDATE: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/`,
    REQUEST_CATEGORIES_DELETE: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/${id}`,

    /** NOTIFICATION URLS*/
    REQUEST_NOTIFICATION_CREATE: (userId: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notification/send/${userId}`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (filter:string, page:number, size:string) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/authority/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}`,
  REQUEST_AUTHORITY: (id: string | null) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
  REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (roleId:number, page:number, size:number, filter:string) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${roleId}/${page}/${size}?filter=${filter}`,

  /** authorityByRole */
  REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && '?filter='+filter}`,


  REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
      CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,
  /** Role urls */
  REQUEST_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ''}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/create`,
  REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,
  REQUEST_ROLE_BY_ID: (id: string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_DELETE: (id: string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ""}`,

  /** users urls */
  REQUEST_USER_LIST: (page: string, size: string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/{page}/{size}/?page=${page}&size=${size}`,
  REQUEST_USER_LIST_FILTER: (page: string, size: string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
  REQUEST_USER: (id: any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
  UPDATE_USER: CONSTANT.DISPATCHER_USER_PREFIX + `/user/`,
  REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
  REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ACTIVATE_USER: (code: string, username: string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/activate?code=${code}&username=${username}`,
  REQUEST_VALIDATE_AVAILABLE_USER: (username: string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/validateUserName?username=${username}`,
  REQUEST_VALIDATE_AVAILABLE_PASSWORD: (userId: number) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/validateNewPassword?userId=${userId}`,

  CANCEL_SUBSCRIPTION_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/unsubscribe",

};
