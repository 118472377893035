import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import ResourceAccess from '../components/security/ResourceAccess';
import { AngleRightIcon, PlusIcon, TrashIcon } from '../components/svgicons/SvgIcons';
import AddAuthorityByRole from '../components/util/AddAuthorityByRole';
import DeleteModal from '../components/util/DeleteModal';
import FancyTablePagination from '../components/util/FancyTablePagination';
import FancyTooltip from '../components/util/FancyTooltip';
import { useApp } from '../hooks/useApp';
import {
  createRequest,
  deleteRequest,
  getRequest,
} from '../services/authority/AuthorityByRoleService';
import { findByIdRequest } from '../services/roles/RoleService';
import { properties } from '../utils/Properties_es';

const SettingAuthorityByRoleManagement = () => {
  const { setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg, errorMsg } = useApp();
  const theme = useTheme();
  const { roleId } = useParams();
  const [preFilter, setPreFilter] = useState<any>('');
  const [page, setPage] = useState(0);
  const [authorityByRoleData, setAuthorityByRoleData] = useState<any>(null);
  const [role, setRole] = useState<any>(null);

  const initForm = {
    id: 0,
    code: '',
    createdBy: '',
    description: '',
    path: 0,
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { t } = useTranslation();


  useEffect(() => {
    let dataInit;
    dataInit = async () => {
      if (roleId) {
        await handleFetchDataRole(roleId);
        await handleFetchData(roleId, 0, '');
      }
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (roleId: string, currentPage: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequest(roleId, currentPage, filter);
      if (data) {
        setAuthorityByRoleData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchDataRole = async (id: string | null) => {
    setLoading && setLoading(true);
    try {
      let roleData: any = await findByIdRequest(id);
      if (roleData) {
        setRole(roleData?.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: any) => {
    //handleCancelModal();
    setLoading && setLoading(true);
    try {
      let authorityID = data.target.getAttribute('data-id');
      let createData = await createRequest({
        authorityId: authorityID,
        roleId: roleId,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg('Error en proceso de guardar permiso');
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      if (roleId) {
        await handleFetchData(roleId, 0, '');
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest({
        authorityId: id,
        roleId: roleId,
      });
      if (!deleteData) {
        setErrorMsg && setErrorMsg('Error en proceso de eliminar permiso');
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);
      //reset page and call fetch data
      setPage(0);
      if (roleId) {
        await handleFetchData(roleId, 0, '');
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page && roleId) {
      await handleFetchData(roleId, customPage, '');
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== '' && roleId) {
      await handleFetchData(roleId, 0, preFilter);
    }
  };

  /** Validate errors in case of opening modal **/
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name');
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');
    if (modalAction === 'delete') {
      object = authorityByRoleData.content.find((p: any) => p.authorityId === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== 'delete') {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'delete':
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.authorityId);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== 'delete') {
      setFormData(initForm);
    }
  };

  return (
    <>
      <Grid container
        justifyContent="center"
        alignItems="center"
        spacing={8} >
        <Typography variant='subtitle1' color='text.primary' sx={{ mb: 2 }}>
          <AngleRightIcon
            sx={{
              marginRight: '0px',
              color: 'primary.main',
              transform: 'translateY(3px)',
              fontSize: '1rem',
            }}
          />{' '}
          Perfil: {role?.name}
        </Typography>
        <Divider />
        <Grid item md={12} sm={6} xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ paddingTop: 5 }}
          >
            <Box
              display="flex"
              alignContent="center"
              alignItems="center"
              gap={4}
            >

              <ResourceAccess isCode={true} pathOrCode={'AUTHORITIESXROLE:WRITE'}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleOpenModal}
                  data-name='create'>
                  Agregar permiso por perfil
                  <PlusIcon sx={{ ml: 1 }} />
                </Button>
              </ResourceAccess>
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Stack
                sx={{
                  paddingTop: 2
                }}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >


              </Stack>
              <Stack
                sx={{
                  paddingTop: 2,
                  paddingRight: 2
                }}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <TextField
                  placeholder='Filtro de búsqueda'
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-root': {
                      borderRadius: '0.2rem 0 0 0.2rem',
                    },
                    '& fieldset': {
                      borderRightWidth: '0',
                    },
                  }}
                  value={preFilter}
                  onChange={(e: any) => {
                    setPreFilter(e.target.value);
                    if (e.target.value === '' && roleId) {
                      handleFetchData(roleId, 0, '');
                    }
                  }}
                  onKeyDown={e => e.key === 'Enter' && handleApplyFilter()}
                />
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleApplyFilter}
                  sx={{
                    borderRadius: '0 0.2rem 0.2rem 0!important',
                    padding: '1.5px 16px!important',
                    minWidth: '20px !important',
                  }}>
                  {t('search')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>


        <Divider />
        <Grid item xs={10}>
          <Stack
            direction="column"
            sx={{}} >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Código</TableCell>
                    <TableCell align='center'>Permiso</TableCell>
                    <TableCell align='center'>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {authorityByRoleData &&
                    authorityByRoleData.content &&
                    authorityByRoleData.content.map((r: any) => (
                      <TableRow key={r.id}>
                        <TableCell component='th' scope='row'>
                          {r.id}
                        </TableCell>
                        <TableCell>{r.authority.code}</TableCell>
                        <TableCell>{r.authority.description}</TableCell>
                        <TableCell align='center'>
                          <ResourceAccess isCode={true} pathOrCode={'AUTHORITIESXROLE:WRITE'}>
                            <FancyTooltip title='Eliminar' placement='top'>
                              <IconButton
                                aria-label='trash'
                                component='label'
                                color='secondary'
                                sx={{
                                  '&:hover': {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={r.authorityId}
                                onClick={handleOpenModal}
                                data-name='delete'>
                                <TrashIcon />
                              </IconButton>
                            </FancyTooltip>
                          </ResourceAccess>
                        </TableCell>
                      </TableRow>
                    ))}
                  {!authorityByRoleData ||
                    !authorityByRoleData.content ||
                    (authorityByRoleData.content?.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align='center'>
                          No existen registros
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!!authorityByRoleData?.content?.length && (
              <FancyTablePagination
                count={
                  authorityByRoleData?.content?.length > 0 ? authorityByRoleData?.content?.length : 0
                }
                rowsPerPage={authorityByRoleData?.size}
                page={page}
                onPageChange={handleChangePage}
                totalElements={authorityByRoleData?.totalElements}
                totalPages={authorityByRoleData?.totalPages}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {modalData?.modalType === 'create' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth maxWidth='md'>
          <AddAuthorityByRole data={roleId} onSubmit={onSubmit} cancelModal={handleCancelModal} />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  //onClick={handleSubmit(onSubmit)}
                  onClick={onSubmit}>
                  {t('save')}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingAuthorityByRoleManagement;
