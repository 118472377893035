import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DocumentTypeField = ({ control, documentType, error, helperText, disabled }: any) => {
    const { t } = useTranslation();

    // Función para obtener el texto del label según el tipo de documento

    const getLabel = () => {
        if (documentType === "C") {
            return t("cedula");
        } else if (documentType === "D") {
            return t("dimex");
        } else {
            return t("email");
        }
    };

    return (
        <Controller
            name="document"
            control={control}
            defaultValue=""
            render={({ field }) => (
                <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label={getLabel()}
                    type={documentType === "E" ? "E" : "text"}
                    variant="standard"
                    autoComplete="document"
                    error={error}
                    helperText={error && helperText}
                    disabled={disabled ?? false}
                    sx={{
                        "& .MuiInput-root:before": {
                            borderBottomColor: "#8bc8bc!important",
                        },
                    }}
                />
            )}
        />
    );
};

export default DocumentTypeField;
