import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { Controller } from "react-hook-form";
import { DataItemCanton, DataItemDistrict, DataItemProvince } from "../../types/auth/Register";

const DistrictSelect = ({ control, name, value, onChange, error, helperText, variant, register, data, province, canton }: any) => {
  const { t } = useTranslation();

  const cantons = province != 0 && canton != 0 && data.filter((item: DataItemProvince) => item.idProvince == province)[0].cantons;

  const districts = cantons && cantons.filter((item: DataItemCanton) => item.idCanton == canton)[0].districts;

  return (
    <Grid item xs={12}>
      <FormControl sx={{ width: "100%" }} error={error}>
        <InputLabel>{t("distrito")}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...register("district")}
              name={name}
              variant="standard"
              label={t("distrito")}
              value={value != 0 ? value : null}
              onChange={onChange}
              autoWidth
              sx={{
                backgroundColor: variant ? "transparent" : "#ffffff",
                borderBottom: variant && "1px solid #8bc8bc",
              }}
            >
              {districts && districts.map((distrito: DataItemDistrict) => (
                <MenuItem key={distrito.idDistrict} value={distrito.idDistrict}>
                  {distrito.nameDistrict}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText sx={{ color: error ? "red" : "initial" }}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default DistrictSelect;
