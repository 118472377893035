import { createContext, useMemo, useState } from 'react';
import { requestTypes } from '../types/Context';

const initialState = {
    setLoading: () => { },
    isLoading: false,
    language: 'en',
    errorMsg: '',
    successMsg: '',
};

const AppContext = createContext<requestTypes>(initialState);

const AppProvider = ({ children, authData }: any) => {
    const [authInfo, setAuthInfo] = useState(authData);
    const [isAdmin, setIsAdmin] = useState(false);
    const [language, setLanguage] = useState(initialState.language);
    const [isLoading, setLoading] = useState(initialState.isLoading);
    const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
    const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
    const [authenticate, setAuthenticate] = useState(authData !== null);
    const [modalData, setModalData] = useState({
        modalOpen: false,
        modalType: "",
        modalObject: null,
    });

    const resetErrorMsg = () => {
        setErrorMsg("");
    };

    const resetSuccessMsg = () => {
        setSuccessMsg("");
    };

    const contextValues = useMemo(() => ({
        authInfo,
        setAuthInfo,
        authenticate,
        setAuthenticate,
        language,
        setLanguage,
        isLoading,
        setLoading,
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        modalData,
        setModalData,
        isAdmin,
        setIsAdmin,
        resetSuccessMsg,
        resetErrorMsg,
    }), [
        authInfo,
        setAuthInfo,
        authenticate,
        setAuthenticate,
        language,
        setLanguage,
        isLoading,
        setLoading,
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        modalData,
        setModalData,
        isAdmin,
        setIsAdmin,
    ]);

    return (
        <AppContext.Provider value={contextValues}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider };

export default AppContext;