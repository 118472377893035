import {
    Grid,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useTheme
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AserriLogo } from "../../../components/svg/icons/SvgIcons";
import { useApp } from "../../../hooks/useApp";
import { step1, step2 } from "../../../types/auth/Register";
import { CONSTANT } from "../../../utils/Constants";
import Footer from "../../../utils/Footer";
import Loading from "../../../utils/Loading";
import MessageManager from "../../../utils/MessageManager";
import { Content, LinkTo } from "../../styles";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const Register: FC = () => {
    const theme = useTheme();
    const [formDataStep1, setFormDataStep1] = useState<step1 | null>(null);
    const [formDataStep2, setFormDataStep2] = useState<any | null>(null);

    const [activeStep, setActiveStep] = useState(0);
    const {
        isLoading,
        successMsg,
        errorMsg,
        resetErrorMsg,
        resetSuccessMsg,
    } = useApp();
    const { t } = useTranslation();

    const handleNextStep1 = (data: step1) => {
        setFormDataStep1(data);
        setActiveStep(1);
    };

    const handleNextStep2 = (data: step2) => {
        setFormDataStep2(data);
        setActiveStep(2);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            {errorMsg && (
                <MessageManager
                    type={CONSTANT.ERROR_MSG}
                    msg={errorMsg}
                    details=""
                    callback={resetErrorMsg}
                />
            )}
            {successMsg && (
                <MessageManager
                    type={CONSTANT.SUCCESS_MSG}
                    msg={successMsg}
                    details=""
                    callback={resetSuccessMsg}
                />
            )}
            {isLoading && <Loading />}

            <Content justifyContent="center" alignItems="center" spacing={2} sx={{ mt: -6, py: 4 }}>
                <AserriLogo />
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: "430px",
                        maxWidth: "100%",
                        position: "relative",
                        margin: "auto",

                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: "center",
                            fontSize: "1.50rem",
                            color: theme.palette.info.dark,
                            // mt: `${theme.spacing(2)}!important`,
                            mb: `${theme.spacing(2)}!important`,
                        }}
                    >
                        {t("register")}
                    </Typography>
                </Stack>
                <Paper sx={{
                    width: "500px",
                    maxWidth: "100%",
                    borderRadius: "25px",
                    background: "#F8F5F3",
                    marginBottom: `${theme.spacing(2)}!important`,
                    padding: theme.spacing(6),
                }}>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ marginBottom: "16px" }}>
                        <Step key={0}>
                            <StepLabel>{t("step1")}</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel>{t("step2")}</StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>{t("step3")}</StepLabel>
                        </Step>
                    </Stepper>

                    {activeStep === 0 && (
                        <Step1 onNextStep={handleNextStep1} preFilledData={formDataStep1} />
                    )}
                    {activeStep === 1 && (
                        <Step2 onNextStep={handleNextStep2} preFilledDataStep1={formDataStep1} preFilledDataStep2={formDataStep2} onBackStep={handleBack} />
                    )}
                    {activeStep === 2 && (
                        <Step3 onBackStep={handleBack}
                            preFilledDataStep1={formDataStep1}
                            preFilledDataStep2={formDataStep2}
                        />
                    )}

                    <Grid item xs={12}>
                        <Typography align="center" sx={{ mt: 4 }}>
                            {t("alreadyHaveAccount")}{" "}
                            <LinkTo to="/">{t("loginHere")}</LinkTo>
                        </Typography>
                    </Grid>
                </Paper>
                <Footer />
            </Content>
        </>
    );
};

export default Register;
