import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { step1 } from "../../../types/auth/Register";
import GenderSelect from "../../../utils/GenderSelect";
import DocumentTypeField from "../../../utils/document/DocumentTypeField";
import DocumentTypeSelect from "../../../utils/document/DocumentTypeSelect";

interface Step1Props {
    onNextStep: (data: step1) => void;
    preFilledData?: step1 | null;
}

const Step1: FC<Step1Props> = ({ onNextStep, preFilledData }) => {
    const { t } = useTranslation();
    const {
        documentTypeRegister,
        setDocumentTypeRegister,
        handleDocumentTypeRegisterChange,
        gender,
        setGender,
        handleGenderChange,
        step1Validation,
    } = useAuth();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
    } = useForm<step1>({
        resolver: yupResolver(step1Validation),
    });

    // Función para establecer los valores predeterminados en los campos del formulario
    const setDefaultValues = (data: step1 | null) => {
        if (data) {
            Object.keys(data).forEach((key) => {
                // Verificar si la clave existe en registerType antes de establecer el valor
                if (key in data) {
                    setValue(key as keyof step1, data[key as keyof step1]);
                }
            });
        }
    };

    if (preFilledData) {
        setDefaultValues(preFilledData);
    }

    const onSubmitForm = (data: step1) => {
        // Validar los campos antes de pasar al siguiente paso
        if (!errors.name && !errors.lastName && !errors.document && !errors.phone) {
            onNextStep(data);
        } else {
            console.error("Hay campos inválidos");
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12} sm={6} >
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("name")}
                                {...register("name")}
                                fullWidth
                                variant="standard"
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Controller
                        {...register("lastName")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("lastName")}
                                fullWidth
                                variant="standard"
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DocumentTypeSelect
                        variant="standard"
                        isRegister={true}
                        value={documentTypeRegister}
                        onChange={(e: any) => handleDocumentTypeRegisterChange(e.target.value)}
                        setDocumentType={setDocumentTypeRegister}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DocumentTypeField
                        control={control}
                        documentType={documentTypeRegister}
                        error={!!errors.document}
                        helperText={errors.document?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Controller
                        {...register("phone")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("phone")}
                                fullWidth
                                variant="standard"
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <Controller
                        name={"username"}
                        control={control}
                        render={() => (
                            <TextField
                                fullWidth
                                size="small"
                                label={t("email")}
                                type="email"
                                variant="standard"
                                autoComplete="username"
                                {...register("username")}
                                error={errors.username && Boolean(errors.username)}
                                helperText={errors.username && errors.username.message}
                                sx={{
                                    "& .MuiInput-root:before": {
                                        borderBottomColor: "#8bc8bc!important",
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <GenderSelect
                        variant="standard"
                        value={gender}
                        onChange={(e: any) => handleGenderChange(e.target.value)}
                        setGender={setGender}
                        error={!!errors.gender}
                        helperText={errors.gender?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name="dob"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t("birthDate")}
                                {...register("dob")}
                                fullWidth
                                variant="standard"
                                error={!!errors.dob}
                                helperText={errors.dob?.message}
                                type="date"
                                placeholder="dd/mm/yyyy"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent={"center"} mt={2} spacing={2}>
                <Grid item xs={6}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        {t("next")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default Step1;
