
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Stack, TextField, Button, Pagination } from "@mui/material";
import { useApp } from "../../hooks/useApp";
import { useTranslation } from "react-i18next";
import { getRequestUser } from "../../services/users/UserService";
import FancyTablePagination from "./FancyTablePagination";

const CHECKS = Array.from(Array(20).keys()).map((x) => `Checkbox ${x}`);

interface ListCheckUserProps {
    listSelectUser: [];
    setListSelectUser: any;
}

const ListCheckUser = ({
    listSelectUser,
    setListSelectUser
}: ListCheckUserProps) => {
    const { t } = useTranslation();
      
    const {
        isAdmin,
        setLoading,
        setErrorMsg,
        setSuccessMsg,
        authInfo
    } = useApp(); 

    const [preFilter, setPreFilter] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [userData, setUserData] = useState<any>({});
    const [userDataView, setUserDataView] = useState<any[]>([]);

    /**
     * Metodo encargado de buscar registros
     * @param filter
     * @param currentPage
     */
    const handleFetchData = async (
        currentPage: number,
        filter?: string
    ) => {
        setLoading && setLoading(true);
        setUserData({});
        setUserDataView([]);
        try{ 
          const sort: string = "creationDate,desc";
          const filterText: string = filter ? filter : '';
          let data: any = await getRequestUser(currentPage,filterText, authInfo.tokenInfo.access_token);
          if (data) {
            setUserData(data);

            if(data && data.content){
                let array : any[] = [];
                data.content.forEach((item: any) => {
                    const isUser = listSelectUser.find((user: any)=> user.id === item.id);
                    array.push({                        
                        email: item.email,
                        name: item.firstName+' '+item.lastName,
                        id: item.id,
                        checked: isUser ? true : false
                    }); 
                });
                setUserDataView(array);
            }
          }
          setLoading && setLoading(false);
        } catch (error: any) {
          setLoading && setLoading(false);
          setErrorMsg && setErrorMsg(error.message);
        }
    };

    /**
     * Evento de aplicar filtro de busqueda
     */
    const handleApplyFilter = async () => {
        if (preFilter !== "") {
          await handleFetchData(0, preFilter);
        }
    };

    /**
     * Metodo encargado de la páginación de la tabla
     * @param event
     * @param newPage
     */
    const handleChangePage = async (event: unknown, newPage: number) => {
        const customPage = newPage - 1;
        setPage(customPage);
        if (customPage !== page) {
        await handleFetchData(customPage);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleSelectUser = (event: any, item: any) => {
        if(event.target.checked){
            const isUser = listSelectUser.find((user: any)=> user.id === item.id);
            if(!isUser)
                setListSelectUser((list: any[])=>[...list,item]);
        }else{
            const listNew = listSelectUser.filter((user: any)=> user.id !== item.id);
            setListSelectUser(listNew);
        }
    };

    /**
     * Efecto para iniciar
     */
    useEffect(() => {
        const dataInit = async () => {
          await handleFetchData(0);
        };
        dataInit();
    }, [authInfo]);

    return (
        <Box sx={{ bgcolor: "background.paper" }}>
            <Stack
                sx={{
                    paddingTop:2,
                    paddingRight:2
                }}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
            >      
                <TextField
                    placeholder={t('filter')}
                    sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                        borderRadius: "0.2rem 0 0 0.2rem",
                    },
                    "& fieldset": {
                        borderRightWidth: "0",
                    },
                    }}
                    value={preFilter}
                    onChange={(e: any) => {
                    setPreFilter(e.target.value);
                    if (e.target.value === "") {
                        handleFetchData(0, "");
                    }
                    }}
                    onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApplyFilter}
                    sx={{
                    borderRadius: "0 0.2rem 0.2rem 0!important",
                    padding: "1.5px 16px!important",
                    minWidth: '20px !important',
                }}
                >
                    {t("search")}
                </Button>
            </Stack>
            <div style={{
                display: "flex",
                flexFlow: "column wrap",
                gap: "0 30px",
                height: 400,
                overflow: "auto"
            }}>
                {userDataView &&
                    userDataView.map((row: any, i: number) => (
                    
                    <ListItem key={row.id} sx={{
                        width: "auto"
                    }}>
                    <ListItemIcon>
                        <Checkbox 
                            edge="start" 
                            disableRipple 
                            checked= {row.checked}
                            onChange={(e: any)=>{
                                row.checked = e.target.checked
                                handleSelectUser(e,row);
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary={row.name} secondary={row.email} />
                    </ListItem>
                ))}
            </div>
            <FancyTablePagination
                count={userData?.content?.length > 0 ? userData?.content?.length : 0}
                rowsPerPage={userData.size}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                totalElements={userData.totalElements}
                totalPages={userData.totalPages}
            />
        </Box>
    );
};

export default ListCheckUser