import * as Yup from "yup";
export interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
}

export const formSchemaOptionsName = {
  id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
};