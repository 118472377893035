import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { properties } from "../../utils/Properties_es";

const Title = styled(DialogTitle)(() => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(() => ({
  borderTop: "1px solid #dee2e6",
}));


interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateRoleModal = ({
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos

  const defaultValues = {
    id: data.id ? data.id : 0,
    adminLogin: data.adminLogin ? data.adminLogin : false,
    clientLogin: data.clientLogin ? data.clientLogin : false,
    description: data.description ? data.description : "",
    name: data.name ? data.name : "",
  };



  interface AddOrUpdateRole {
    id?: number;
    adminLogin: boolean;
    clientLogin: boolean;
    description: string;
    name: string;
  }
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateRole>({
    defaultValues,
    resolver: yupResolver(Yup.object({
      adminLogin: Yup.boolean().required("Campo es requerido"),
      clientLogin: Yup.boolean().required("Campo es requerido"),
      description: Yup.string().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido")
    })),
    mode: "onChange"
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? properties.com_parval_label_user_button_update_role
          : properties.com_parval_label_user_button_add_role}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="description"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateRoleModal;
