import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../hooks/useApp";
import { Department } from "../../../services/department/DepartmentInterfaces";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

enum ApartmentField {
  Id = "id",
  Name = "name"
}
interface AddOrUpdateCategories {
  name: string;
}

const AddOrUpdateCategoriesModal = ({
    actionButton,
    data,
    onSubmit,
    cancelModal,
    modalType
    }: DialogProps) => {
    const { t } = useTranslation();
    // Validación de datos

    const [departmentData, setDepartmentData] = useState<Department[]>([]);  
    const [timeoutUser,setTimeoutUser] = useState<any>(null);
    const { setLoading, 
        setErrorMsg, 
        isAdmin, 
        setSuccessMsg,
        authInfo 
    } = useApp();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();
  }, [authInfo]);
  

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage?: number,
    filter?: string,
    applyRole?: any
  ) => {
    setDepartmentData([]);
    try {
      //setPage(currentPage);
      /*let departments = await getRequest(currentPage? currentPage : 0,);
      if (departments) {
        setDepartmentData(departments.content);
      }*/
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };


  const validation = Yup.object().shape({
    name: Yup.string().required(t("fieldRequired"))
  });

  // Obtener valores predeterminados
  const defaultValues = {
    id: data ? data.id : 0,
    name: data ? data.name : ''
  };

  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateCategories>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? 'Actualizar categoría'
          : 'Agregar categoría'}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'name'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("name")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='name'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('name')}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>            
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateCategoriesModal;
