import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button, Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow,
  useTheme
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceAccess from "../components/security/ResourceAccess";
import {
  ConfigIcon,
  PenIcon,
  PlusIcon,
  TrashIcon,
} from "../components/svgicons/SvgIcons";
import AddOrUpdateRoleModal from "../components/util/AddOrUpdateRoleModal";
import DeleteModal from "../components/util/DeleteModal";
import FancyTooltip from "../components/util/FancyTooltip";
import { useApp } from "../hooks/useApp";
import {
  createRequest,
  deleteRequest,
  findByIdRequest,
  getRequest,
  updateRequest,
} from "../services/roles/RoleService";
import { properties } from "../utils/Properties_es";

const SettingRoles = () => {
  const { t } = useTranslation();

  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [roleData, setRoleData] = useState<any>([]);
  const initForm = {
    id: 0,
    adminLogin: "",
    clientLogin: "",
    description: "",
    name: "",
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (
    currentPage?: number,
    filter?: string
  ) => {
    setLoading && setLoading(true);
    try {
      //setPage(currentPage);

      let data = await getRequest();
      if (data) {
        setRoleData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let roleData = await findByIdRequest(id);
      //@ts-ignore
      if (roleData && roleData?.content?.id > 0) {
        //@ts-ignore
        setFormData(roleData.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_role_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    if (modalAction === "update") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = roleData.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center"
        spacing={8} >
        <Grid item md={12} sm={6} xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ paddingTop: 5 }}
          >
            <Box
              sx={{
                paddingTop: 2,
                paddingLeft: 2
              }}
              display="flex"
              alignContent="center"
              alignItems="center"
              gap={4}
            >

              <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  data-name="create"
                >
                  {t('addProfile')}
                  <PlusIcon sx={{ ml: 1 }} />
                </Button>
              </ResourceAccess>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack
            direction="column"
            sx={{}} >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('id')}</TableCell>
                    <TableCell>{t('name')}</TableCell>
                    <TableCell align="center">{t('description')}</TableCell>
                    <TableCell align="center">{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleData &&
                    roleData.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell align="center">{row.description}</TableCell>
                        <TableCell align="center">
                          <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                            <FancyTooltip title="Editar" placement="top">
                              <IconButton
                                aria-label="edit"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                onClick={handleOpenModal}
                                data-name="update"
                                data-id={row.id}
                              >
                                <PenIcon />
                              </IconButton>
                            </FancyTooltip>
                            <FancyTooltip
                              title="Permisos por perfil"
                              placement="top"
                            >
                              <Link to={"/dashboard/authoritiesbyrole/" + row.id}>
                                <IconButton
                                  aria-label="edit"
                                  component="label"
                                  color="secondary"
                                  sx={{
                                    "&:hover": {
                                      color: theme.palette.secondary.dark,
                                    },
                                  }}
                                  data-name="update"
                                  data-id={row.id}
                                >
                                  <ConfigIcon />
                                </IconButton>
                              </Link>
                            </FancyTooltip>

                            <FancyTooltip title="Eliminar" placement="top">
                              <IconButton
                                aria-label="trash"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={row.id}
                                onClick={handleOpenModal}
                                data-name="delete"
                              >
                                <TrashIcon />
                              </IconButton>
                            </FancyTooltip>
                          </ResourceAccess>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>

      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
          >
            <AddOrUpdateRoleModal
              data={formData}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('save')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingRoles;
