import { UrlBase } from "../../url/Urls";
import { CONSTANT } from "../../utils/Constants";
import { privateFetch, publicFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { URL } from "../../utils/UrlConstants";
import { Department, DepartmentResponse } from "./DepartmentInterfaces";

export const getRequestDepartment = async (): Promise<DepartmentResponse> => {

    try {
      const response = await publicFetch.get(URL.REQUEST_DEPARMENT());
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };
  
  // export const getRequestById = async (id: number): Promise<DepartmentResponse> => {
  
  //   try {
  //     const response = await publicFetch.get(URL.REQUEST_DEPARTMENT_BY_ID(id));
  //     return response.data;
  //   } catch (error: any) {
  //     throw new Error(error);
  //   }
  // };
  
  // export const createRequest = async (data: Department) => {
  //   let responseData = {};

  //   const requestBody = data;

  //   const urlCreate = URL.REQUEST_DEPARTMENT_CREATE();
    
  //   await publicFetch
  
  //     .post(urlCreate, requestBody, {
  //       headers: {
  //         ...CONSTANT.HEADER_TYPE,
  //       },
  //     })
  
  //     .then(async (response) => {
  //       responseData = await response.data;
  //     })
  //     .catch(async (error) => {
  //       if (error.response?.data?.code && error.response?.data?.code === '002'
  //         && error.response?.data?.message && error.response?.data?.message !== '')
  //         throw new Error(error.response.data.message);
  //       throw new Error(properties.ERROR_CREATING_RECORD);
  //     });
  //   return responseData;
  // };
  
  // export const updateRequest = async (data: any) => {
  //   let responseData = {};
    
  //   const requestBody = data;

  //   await publicFetch
  //     .put(URL.REQUEST_DEPARTMENT_UPDATE(), requestBody, {
  //       headers: {
  //         ...CONSTANT.HEADER_TYPE,
  //       },
  //     })
  
  //     .then(async (response) => {
  //       responseData = await response.data;
  //     })
  //     .catch(async (error) => {
  //       if (error.response?.data?.code && error.response?.data?.code === '002'
  //         && error.response?.data?.message && error.response?.data?.message !== '')
  //         throw new Error(error.response.data.message);
  //       throw new Error(properties.ERROR_UPDATING_RECORD);
  //     });
  //   return responseData;
  // };
  
  
  // export const deleteRequest = async (id: number) => {
  //   let responseData = {};
  //   await publicFetch
  //     .delete(URL.REQUEST_DEPARTMENT_DELETE(id), {})
  //     .then(async (response) => {
  //       responseData = await response.data;
  //     })
  //     .catch(async (error) => {
  //       throw new Error(
  //         properties.ERROR_DELETING_RECORD
  //       );
  //     });
  //   return responseData;
  // };