import { Button, Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { step1, step2 } from "../../../types/auth/Register";
import ProvinceSelect from "../../../utils/address/ProvinceSelect";
import { useForm } from "react-hook-form";
import CantonSelect from "../../../utils/address/CantonSelect";
import DistrictSelect from "../../../utils/address/DistrictSelect";
import { useAuth } from "../../../context/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";


interface Step2Props {
    onNextStep: (data: step2) => void;
    onBackStep: () => void;
    preFilledDataStep1?: step1 | null;
    preFilledDataStep2?: step2 | null;
}


const Step2: FC<Step2Props> = ({ onNextStep, onBackStep, preFilledDataStep1, preFilledDataStep2 }) => {
    const { t } = useTranslation();
    const [provinceSelected, setProvinceSelected] = useState(0);
    const [cantonSelected, setCantonSelected] = useState(0);
    const [districtSelected, setDistrictSelected] = useState(0);
    const [terms, setTerms] = useState<boolean>(false);
    const [termsError, setTermsError] = useState<string>("");
    const {
        step2Validation,
        dataProvinces
    } = useAuth();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue
    } = useForm<step2>({
        resolver: yupResolver(step2Validation),
    });


    const setDefaultValues = (data: step2 | null) => {
        if (data) {
            Object.keys(data).forEach((key) => {
                if (key in data) {
                    setValue(key as keyof step2, data[key as keyof step2]);
                }
            });
        }
    };


    useEffect(() => {
        if (preFilledDataStep2) {
            setDefaultValues(preFilledDataStep2);
            const prevProvinceSelected: number = preFilledDataStep2?.province ? preFilledDataStep2.province : 0
            const prevCantonSelected: number = preFilledDataStep2?.canton ? preFilledDataStep2.canton : 0
            const prevDistrictSelected: number = preFilledDataStep2?.district ? preFilledDataStep2.district : 0
            setProvinceSelected(prevProvinceSelected)
            setCantonSelected(prevCantonSelected)
            setDistrictSelected(prevDistrictSelected)
        }
    }, [step2Validation])



    const onSubmitForm = (data: step2) => {
        if (validateTerms()) {
            onNextStep(data);
        }
    };


    const handleProvinceSelected = (event: { target: { value: SetStateAction<number>; }; }) => {
        setProvinceSelected(event.target.value);
        setCantonSelected(0)
        setDistrictSelected(0)
    };


    const handleCantonSelected = (event: { target: { value: SetStateAction<number>; }; }) => {
        setCantonSelected(event.target.value)
        setDistrictSelected(0)
    };

    const handleChangeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerms(event.target.checked);
        setTermsError(""); // Clear error when checkbox is interacted with
    };

    const validateTerms = () => {
        if (!terms) {
            setTermsError("Debe aceptar los términos y condiciones.");
            return false;
        }
        return true;
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container justifyContent="center" mt={2} spacing={2}>
                <Grid item xs={12}>
                    <ProvinceSelect
                        control={control}
                        name="province"
                        variant="standard"
                        value={provinceSelected}
                        onChange={handleProvinceSelected}
                        error={!!errors.province}
                        helperText={errors.province?.message}
                        register={register}
                        data={dataProvinces}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CantonSelect
                        control={control}
                        name="canton"
                        variant="standard"
                        value={cantonSelected}
                        onChange={handleCantonSelected}
                        error={!!errors.canton}
                        helperText={errors.canton?.message}
                        register={register}
                        data={dataProvinces}
                        province={provinceSelected}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DistrictSelect
                        control={control}
                        name="district"
                        variant="standard"
                        value={districtSelected}
                        onChange={(e: any) => setDistrictSelected(e.target.value)}
                        error={!!errors.district}
                        helperText={errors.district?.message}
                        register={register}
                        data={dataProvinces}
                        province={provinceSelected}
                        canton={cantonSelected}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={terms} onChange={handleChangeTerms} name="terms" />
                        }
                        label={
                            <Typography variant="body2">
                                Al crear cuenta aceptas los {' '}
                                <a
                                    href="https://aserri.go.cr/politica-privacidad/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'black', fontWeight: 'bold' }}
                                >
                                    Términos y condiciones legales y Políticas de Privacidad
                                </a>
                            </Typography>
                        }
                    />
                    {termsError && (
                        <Typography variant="body2" color="error">
                            {termsError}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={onBackStep} variant="outlined" fullWidth sx={{ textTransform: "none" }}>
                        {t("back")}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        {t("next")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default Step2;
