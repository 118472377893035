import { Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardWidget from './widgets/CardHome';

const cards = [
    {title: 'Pagos', icon: 'pending_queries', iconColor: '#066FC5', route: "https://comercio.ifam.go.cr/aserri"},
    { title: 'Denuncias y sugerencias', icon: 'account_statements', iconColor: '#A12E20', route: '/dashboard/complaint' },
    { title: 'Directorio interno', icon: 'internal_directory', iconColor: '#1B5E23', route: '/dashboard/internal-directory' },
    { title: 'Ubicación', icon: 'location', iconColor: '#F3CD3D', route: '/dashboard/geographic-location' },
];

const Content = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    marginTop: 16,
    padding: 16,
});

const StyledContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const Home: React.FC = () => {
    const navigate = useNavigate();

    const handleCardClick = (route: string) => {
        if (route.startsWith('https')) {
            window.open('https://comercio.ifam.go.cr/aserri', '_blank');
        } else {
            navigate(route);
        }
    };

    return (
        <Content>
            <StyledContainer maxWidth="md">
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    {cards.map((card, index) => (
                        <Grid item xs={12} md={6} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                            <CardWidget {...card} onTap={() => handleCardClick(card.route)} />
                        </Grid>
                    ))}
                </Grid>
            </StyledContainer>
        </Content>
    );
};

export default Home;
