import ReactPasswordChecklist, { RuleNames } from "react-password-checklist";

import { useTranslation } from "react-i18next";
import "../index.css";

const CustomPasswordChecklist = ({ value, valueAgain, onChange }: any) => {
  const { t } = useTranslation();


  // Define las reglas de validación basadas en las condiciones
  const rules = [
    value.length  < 6 ? "minLength" : null,
    !/[!@#$%^&*(),.?":{}|<>]/.test(value) ? "specialChar" : null,
    !/\d/.test(value) ? "number" : null,
    !/[A-Z]/.test(value) ? "capital" : null,
    !/[a-z]/.test(value) ? "lowercase" : null,
    value.length > 10 ? "maxLength" : null,
    // valueAgain  &&  "match",
  ].filter((rule) => rule !== null) as RuleNames[];
  if (valueAgain !== null) {
    rules.push("match");
  }

  // Define los mensajes correspondientes a cada regla de validación
  const messages = {
    minLength: t("minLength"),
    maxLength: t("maxLength"),
    specialChar: t("specialChar"),
    number: t("number"),
    capital: t("capital"),
    lowercase: t("lowercase"),
    match: t("match"),
  };

  return (
    <ReactPasswordChecklist
      iconSize={16}
      className="react-password-checklist-ul"
      rules={rules}
      minLength={6}
      maxLength={10}
      value={value}
      valueAgain={valueAgain ?? ""}
      onChange={onChange}
      messages={messages}
    />
  );
};

export default CustomPasswordChecklist;
