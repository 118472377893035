import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../hooks/useApp";
import { Department } from "../../../services/department/DepartmentInterfaces";
import { Address } from "../../../services/address/AddressInterfaces";
import { getRequestDepartment } from "../../../services/department/DepartmentService";
import { getRequestAddress } from "../../../services/address/AddressService";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
}

enum ApartmentField {
  Id = "id",
  Name = "name",
  Mail = "mail",
  DirectNumber = "directNumber",
  Extension = "extension",
  Department = "department",
  Address = "address",
  AnalogicNumber = "analogicNumber",
  AnalogicExtension = "analogicExtension",
}
interface AddOrUpdateInternalDirectory {
  user: string;
  mail: string;
  directNumber: string;
  extension: string;
  department?: {
    id?: number;
    name?: string;
  };
  address?: {
    id?: number;
    name?: string;
  };
  analogicExtension: string;
  analogicNumber: string;
}

const AddOrUpdateInternalDirectoryModal = ({
    actionButton,
    data,
    onSubmit,
    cancelModal,
    modalType
    }: DialogProps) => {
    const { t } = useTranslation();
    // Validación de datos

    const [departmentData, setDepartmentData] = useState<Department[]>([]);  
    const [addressData, setAddressData] = useState<Address[]>([]);  
    const [timeoutUser,setTimeoutUser] = useState<any>(null);
    const { setLoading, 
        setErrorMsg, 
        isAdmin, 
        setSuccessMsg,
        authInfo 
    } = useApp();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchDataDepartament();
      await handleFetchDataAddress();
    };
    dataInit();
  }, [authInfo]);
  

  /**
   * Metodo encargado de buscar registros de departamentos
   * @param filter
   * @param currentPage
   */
  const handleFetchDataDepartament = async () => {
    setDepartmentData([]);
    try {
      let departments = await getRequestDepartment();
      if (departments) {
        setDepartmentData(departments.content);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };
  

  /**
   * Metodo encargado de buscar registros de direcciones
   * @param filter
   * @param currentPage
   */
  const handleFetchDataAddress = async () => {
    setAddressData([]);
    try {
      let addresses = await getRequestAddress();
      if (addresses) {
        setAddressData(addresses.content);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const validation = Yup.object().shape({
    user: Yup.string().required(t("fieldRequired")),
    mail: Yup.string().required(t("fieldRequired")),
    directNumber: Yup.string().required(t("fieldRequired")),
    extension: Yup.string().required(t("fieldRequired")),
    analogicExtension: Yup.string().required(t("fieldRequired")),
    analogicNumber: Yup.string().required(t("fieldRequired"))
  });

  // Obtener valores predeterminados
  const defaultValues = {
    id: data ? data.id : 0,
    user: data ? data.user : '',
    mail: data ? data.mail : '',
    directNumber : data ? data.directNumber : '',
    extension: data ? data.extension : '',
    department: data ? data.department : null,
    address: data ? data.address : null,
    analogicExtension: data ? data.analogicExtension:'',
    analogicNumber: data ? data.analogicNumber:''
  }


  // Inicializar useForm con validación y valores predeterminados
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateInternalDirectory>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? 'Actualizar directorio'
          : 'Agregar directorio'}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'user'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={t("name")}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='user'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('user')}
                    error={errors.user && Boolean(errors.user)}
                    helperText={errors.user && errors.user.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'mail'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={'Email'}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='mail'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('mail')}
                    error={errors.mail && Boolean(errors.mail)}
                    helperText={errors.mail && errors.mail.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'directNumber'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={'Número directo'}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='directNumber'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('directNumber')}
                    error={errors.directNumber && Boolean(errors.directNumber)}
                    helperText={errors.directNumber && errors.directNumber.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'extension'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={'Extensión'}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='extension'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('extension')}
                    error={errors.extension && Boolean(errors.extension)}
                    helperText={errors.extension && errors.extension.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'analogicNumber'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={'Número Analógico'}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='analogicNumber'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('analogicNumber')}
                    error={errors.analogicNumber && Boolean(errors.analogicNumber)}
                    helperText={errors.analogicNumber && errors.analogicNumber.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'analogicExtension'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label={'Extensión Analógico'}
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='analogicExtension'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('analogicExtension')}
                    error={errors.analogicExtension && Boolean(errors.analogicExtension)}
                    helperText={errors.analogicExtension && errors.analogicExtension.message}
                  />
                )}
              />
            </Grid>
            {modalType === "create" && (
              <Grid item xs={12}>    
                <Controller
                      name={"address"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl variant="standard"  fullWidth>
                          <InputLabel id="simple-select-standard-label-address">{'Dirección'}</InputLabel>
                          <Select
                            labelId="simple-select-standard-label-address"
                            label={'Dirección'}
                            variant="standard"
                            value={value}
                            {...register("address")}
                          >
                          {addressData &&
                              addressData.map((row: any, i: number) => (
                            <MenuItem
                              key={row.id}
                              value={row.id}
                            >
                              {row.name}
                            </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                      )}
                />
              </Grid>
            )}
            {modalType === "create" && (   
              <Grid item xs={12}>               
                <Controller
                      name={"department"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl variant="standard"  fullWidth>
                          <InputLabel id="simple-select-standard-label-department">{'Departamento'}</InputLabel>
                          <Select
                            labelId="simple-select-standard-label-department"
                            label={'Departamento'}
                            variant="standard"
                            value={value}
                            {...register("department")}
                          >
                            {departmentData &&
                                departmentData.map((row: any, i: number) => (
                              <MenuItem
                                key={row.id}
                                value={row.id}
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                />         
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          {t("cancel")}
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateInternalDirectoryModal;
